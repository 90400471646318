import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { debounce } from "lodash";
import {
  Col,
  Row,
  Layout,
  Button,
  Table,
  Input,
  Space,
  Breadcrumb,
  Popconfirm,
  Select,
} from "antd";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
  // HeartOutlined,
} from "@ant-design/icons";
import "../dashboard/Dashboard.scss";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData } from "../../../utils/Helpers";
import AdminSidebar from "../common/AdminSidebar";
import {
  PRODUCT_LIST_QUERY,
  CHANGE_PRODUCT_STATUS_QUERY,
  DELETE_PRODUCT_QUERY,
  CHANGE_PRODUCT_SHOW_ON_LANDING_PAGE_QUERY,
} from "../../brand/product/ProductQuery";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
class ManageProduct extends Component {
  state = {
    data: [],
    loading: false,
    popConfirmShowStatus: false,
    editIndexStatus: null,
    popConfirmShowLanding: false,
    editIndexLanding: null,
    perPageRecord: 20,
    nextPageId: "",
    previousPageId: "",
    currentPageNo: 1,
    totalPage: 1,
    totalRecords: 0,
    orderBy: "-product_id",
    authUserId: authUserData().appusersId,
    filter: { deleted: 0 },
  };

  componentDidMount() {
    this.fetchProductList({
      deleted: 0,
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
    });

    this.searchCallback = debounce(function (e) {
      this.searchProduct(e.target.value);
    }, 500);
  }

  handleTableChange = (pagination, filters, sorter) => {
    const orderBy = this.GetSortingEnumValue(sorter.columnKey, sorter.order);
    this.setState({ orderBy: orderBy, currentPageNo: 1 });

    this.fetchProductList({
      deleted: 0,
      orderBy: orderBy,
      first: this.state.perPageRecord,
    });
  };

  fetchProductList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: PRODUCT_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data: result.data.allProduct.edges,
          nextPageId: result.data.allProduct.pageInfo.endCursor,
          previousPageId: result.data.allProduct.pageInfo.startCursor,
          totalRecords: result.data.allProduct.filterCount,
          totalPage: Math.ceil(
            result.data.allProduct.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  nextPage = (params = {}) => {
    this.fetchProductList({
      deleted: 0,
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
      after: this.state.nextPageId,
    });

    if (this.state.currentPageNo !== this.state.totalPage) {
      this.setState({ currentPageNo: this.state.currentPageNo + 1 });
    }
  };

  previousPage = (params = {}) => {
    this.fetchProductList({
      deleted: 0,
      orderBy: this.state.orderBy,
      last: this.state.perPageRecord,
      before: this.state.previousPageId,
    });

    if (this.state.currentPageNo !== 1) {
      this.setState({ currentPageNo: this.state.currentPageNo - 1 });
    }
  };

  handlePerPageRecord = (e) => {
    this.fetchProductList({
      deleted: 0,
      first: e,
    });
    this.setState({ perPageRecord: e, currentPageNo: 1 });
  };

  searchCallbackDelayed = (e) => {
    e.persist();
    this.searchCallback(e);
  };

  searchProduct = (searchText) => {
    let searchObj = { deleted: 0 };

    if (searchText !== "") {
      this.fetchProductList({
        search: searchText,
        deleted: 0,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
      });

      this.setState({ filter: searchObj, currentPageNo: 1 });
    } else {
      this.fetchProductList({
        search: searchText,
        deleted: 0,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
      });

      this.setState({ filter: searchObj });
    }
  };

  GetSortingEnumValue(sortField, sortOrder) {
    let sortingEnumKey = "product_name";

    if (sortField === "productName" && sortOrder === "ascend") {
      sortingEnumKey = "product_name";
    }

    if (sortField === "productName" && sortOrder === "descend") {
      sortingEnumKey = "-product_name";
    }

    if (sortField === "price" && sortOrder === "ascend") {
      sortingEnumKey = "price";
    }

    if (sortField === "price" && sortOrder === "descend") {
      sortingEnumKey = "-price";
    }

    if (sortField === "status" && sortOrder === "ascend") {
      sortingEnumKey = "status";
    }

    if (sortField === "status" && sortOrder === "descend") {
      sortingEnumKey = "-status";
    }

    if (sortField === "showonLandingPage" && sortOrder === "ascend") {
      sortingEnumKey = "showon_landing_page";
    }

    if (sortField === "showonLandingPage" && sortOrder === "descend") {
      sortingEnumKey = "-showon_landing_page";
    }
    return sortingEnumKey;
  }

  confirmDelete = async (productId, props) => {
    const { client } = props;
    await client
      .mutate({
        mutation: DELETE_PRODUCT_QUERY,
        variables: { productId: productId },
      })
      .then((result) => {
        this.fetchProductList({
          deleted: 0,
          orderBy: this.state.orderBy,
          first: this.state.perPageRecord,
        });
        this.setState({ currentPageNo: 1 });
        SuccessNotificationMsg("Success", "Product deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  showStatusPopconfirm = (index) => {
    this.setState({
      editIndexStatus: index,
      popConfirmShowStatus: true,
    });
  };

  handleCancelPopConfirmStatus = () => {
    this.setState({
      editIndexStatus: null,
      popConfirmShowStatus: false,
    });
  };

  handleChangeStatus = (record) => {
    let status = record.node.status === true ? false : true;

    const { client } = this.props;
    client
      .mutate({
        mutation: CHANGE_PRODUCT_STATUS_QUERY,
        variables: {
          productId: record.node.productId,
          status: status,
          userId: record.node.userId.appusersId,
        },
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Product status change successfully");
        this.setState({
          editIndexStatus: null,
          popConfirmShowStatus: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({
          editIndexStatus: null,
          popConfirmShowStatus: false,
        });
      });
  };

  showLandingPopconfirm = (index) => {
    this.setState({
      editIndexLanding: index,
      popConfirmShowLanding: true,
    });
  };

  handleCancelPopConfirmLanding = () => {
    this.setState({
      editIndexLanding: null,
      popConfirmShowLanding: false,
    });
  };

  handleChangeStatusLanding = (record) => {
    let showonLandingPage =
      record.node.showonLandingPage === true ? false : true;

    const { client } = this.props;
    client
      .mutate({
        mutation: CHANGE_PRODUCT_SHOW_ON_LANDING_PAGE_QUERY,
        variables: {
          productId: record.node.productId,
          showonLandingPage: showonLandingPage,
          userId: record.node.userId.appusersId,
        },
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Change successfully");
        this.setState({
          editIndexLanding: null,
          popConfirmShowLanding: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({
          editIndexLanding: null,
          popConfirmShowLanding: false,
        });
      });
  };

  render() {
    const { data, loading } = this.state;
    const columns = [
      {
        title: "Product ID",
        key: "productId",
        sorter: true,
        render: (node) => `${node.node.productId}`,
        width: "10%",
      },
      {
        title: "Product name",
        key: "productName",
        sorter: true,
        render: (node) => `${node.node.productName}`,
        width: "18%",
      },
      {
        title: "Brand Name",
        key: "brandName",
        sorter: false,
        render: (node) =>
          `${
            node.node.userId.onboardingmodelSet.edges.length > 0
              ? node.node.userId.onboardingmodelSet.edges[0].node.company
              : ""
          }`,
        width: "17%",
      },
      {
        title: "Price",
        key: "price",
        sorter: true,
        render: (node) => `${node.node.price}`,
        width: "10%",
      },
      {
        title: "Visit Count",
        key: "clickCount",
        render: (node) => `${node.node.clickCount}`,
        width: "10%",
      },
      {
        title: "Show on landing",
        key: "showonLandingPage",
        sorter: true,
        render: (node) => {
          return (
            <React.Fragment>
              {this.state.editIndexLanding === node.node.productId ? (
                <Popconfirm
                  className="action"
                  title="Are you sure to change ?"
                  onConfirm={(id) => this.handleChangeStatusLanding(node)}
                  onCancel={() => this.handleCancelPopConfirmLanding()}
                  okText="Yes"
                  placement="left"
                  visible={this.state.popConfirmShowLanding}
                >
                  <Select
                    defaultValue={
                      node.node.showonLandingPage === true ? "yes" : "no"
                    }
                    onChange={() =>
                      this.showLandingPopconfirm(node.node.productId)
                    }
                    style={{ width: 95 }}
                    size="small"
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No </Option>
                  </Select>
                </Popconfirm>
              ) : (
                <Select
                  defaultValue={
                    node.node.showonLandingPage === true ? "yes" : "no"
                  }
                  onChange={() =>
                    this.showLandingPopconfirm(node.node.productId)
                  }
                  style={{ width: 95 }}
                  size="small"
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No </Option>
                </Select>
              )}
            </React.Fragment>
          );
        },
        width: "15%",
      },
      {
        title: "Status",
        key: "status",
        sorter: true,
        render: (node) => {
          return (
            <React.Fragment>
              {this.state.editIndexStatus === node.node.productId ? (
                <Popconfirm
                  className="action"
                  title="Are you sure to change status of product ?"
                  onConfirm={(id) => this.handleChangeStatus(node)}
                  onCancel={() => this.handleCancelPopConfirmStatus()}
                  okText="Yes"
                  placement="left"
                  visible={this.state.popConfirmShowStatus}
                >
                  <Select
                    defaultValue={
                      node.node.status === true ? "active" : "inactive"
                    }
                    onChange={() =>
                      this.showStatusPopconfirm(node.node.productId)
                    }
                    style={{ width: 95 }}
                    size="small"
                  >
                    <Option value="active">Active</Option>
                    <Option value="inactive">Inactive </Option>
                  </Select>
                </Popconfirm>
              ) : (
                <Select
                  defaultValue={
                    node.node.status === true ? "active" : "inactive"
                  }
                  onChange={() =>
                    this.showStatusPopconfirm(node.node.productId)
                  }
                  style={{ width: 95 }}
                  size="small"
                >
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive </Option>
                </Select>
              )}
            </React.Fragment>
          );
        },
        width: "10%",
      },
      {
        width: "10%",
        fixed: "right",
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        render: (text, node) => (
          <Space size="middle">
            <Link
              to={"/admin-edit-product/" + btoa(node.node.productId)}
              className="action"
              title="Edit Product"
            >
              <EditOutlined />
            </Link>
            <Popconfirm
              className="action"
              title="Are you sure delete this product ?"
              onConfirm={(id) =>
                this.confirmDelete(node.node.productId, this.props)
              }
              okText="Yes"
              placement="left"
            >
              <DeleteOutlined />
            </Popconfirm>
            <Link
              to={"/admin-product-document/" + btoa(node.node.productId)}
              className="action"
              title="Product Document"
            >
              <UploadOutlined />
            </Link>
            {/* <Link
              to={"/admin-product-like-list/" + btoa(node.node.productId)}
              className="action"
              title="Product Like List"
            >
              <HeartOutlined />
            </Link> */}
          </Space>
        ),
      },
    ];

    return (
      <div className="contentpart">
        <Layout>
          <AdminSidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Manage Product
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/admin-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Manage Product</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>Products</h3>
                      </Col>
                      <Col flex="auto" align="end">
                        <Space>
                          <div className="searchhproduct">
                            <Search
                              placeholder="input search text"
                              onChange={this.searchCallbackDelayed}
                            />
                          </div>
                          <Link to="/admin-add-product">
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="small"
                              icon={<PlusCircleOutlined />}
                            >
                              ADD PRODUCT
                            </Button>
                          </Link>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                  <Table
                    className="table_grid"
                    columns={columns}
                    rowKey={(record) => record.node.id}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    onChange={this.handleTableChange}
                  ></Table>

                  <div className="paginate">
                    <div>
                      <Select
                        defaultValue={this.state.perPageRecord + " / page"}
                        onChange={(e) => this.handlePerPageRecord(e)}
                        style={{ width: 120 }}
                      >
                        <Option value="5">5 / page</Option>
                        <Option value="10">10 / page</Option>
                        <Option value="20">20 / page</Option>
                        <Option value="50">50 / page</Option>
                      </Select>
                      Total Records : {this.state.totalRecords}
                    </div>
                    <div className="pages-count">
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== 1
                            ? this.previousPage
                            : ""
                        }
                      >
                        <LeftOutlined />
                      </Button>
                      &nbsp;&nbsp;{this.state.currentPageNo} /{" "}
                      {this.state.totalPage} &nbsp;&nbsp;
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== this.state.totalPage
                            ? this.nextPage
                            : ""
                        }
                      >
                        <RightOutlined />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ManageProduct);
