import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Col, Row, Checkbox, Button } from "antd";
import { isMobile } from "react-device-detect";
import qs from "query-string";

import ProductLike from "../ProductLike";
import ProductListSkeleton from "./ProductListSkeleton";
import ProductFilter from "./ProductFilter";
import ProductListBlog from "./ProductListBlog";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { PRODUCT_LIST_QUERY } from "../../brand/product/ProductQuery";

import productDefaultImg from "../../../images/noimg-product.jpg";
import CO21 from "../../../images/CO21.png";
import water1 from "../../../images/water1.png";
import distance1 from "../../../images/distance1.png";
export class ProductList extends Component {
  state = {
    loading: false,
    orderBy: "-product_id",
    data: [],
    compareProductArr: [],
    perPageRecord: 8,
    currentPageNo: 1,
    defaultFilterParameter: {
      deleted: 0,
      status: true,
      orderBy: "-product_id",
      first: 8,
      after: null,
    },
  };

  componentDidMount() {
    this.filterProduct();
    this.setState({
      compareProductArr:
        this.props.location.state && this.props.location.state.compareProductArr
          ? this.props.location.state.compareProductArr
          : [],
    });
  }

  // componentDidUpdate(prevProps) {
  //   if (
  //     this.props.history.location.search !== undefined &&
  //     this.state.filterParameter.qs !== this.props.history.location.search
  //   ) {
  //     this.filterProduct();
  //   }
  // }

  fetchProductList = () => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: PRODUCT_LIST_QUERY,
        variables: this.state.filterParameter,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data:
            this.state.data.length > 0
              ? this.state.data.concat(result.data.allProduct.edges)
              : result.data.allProduct.edges,
          nextPageId: result.data.allProduct.pageInfo.endCursor,
          totalRecords: result.data.allProduct.filterCount,
          totalPage: Math.ceil(
            result.data.allProduct.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  filterProduct = () => {
    let filterParams = this.prepareFilterData();
    //console.log("come", filterParams);
    let filterParameter = {
      ...this.state.defaultFilterParameter,
      ...filterParams,
      after: null,
    };
    this.setState({ data: [], filterParameter, currentPageNo: 1 }, () => {
      this.fetchProductList();
    });
  };

  prepareFilterData = () => {
    let queryString = qs.parse(this.props.history.location.search);
    let response = {};

    response.qs = this.props.history.location.search;

    if ("gender" in queryString && queryString.gender !== "") {
      let genderArr = queryString.gender.split(",");
      if (genderArr.length > 0) {
        response["genderlist"] = genderArr;
      }
    }

    if ("category" in queryString && queryString.category !== "") {
      let categoryArr = queryString.category.split(",");
      if (categoryArr.length > 0) {
        response["categorylist"] = categoryArr;
      }
    }

    if ("size" in queryString && queryString.size !== "") {
      let sizeArr = queryString.size.split(",");
      if (sizeArr.length > 0) {
        response["sizelist"] = sizeArr;
      }
    }

    if ("material" in queryString && queryString.material !== "") {
      let materialArr = queryString.material.split(",");
      if (materialArr.length > 0) {
        materialArr.map((material) => {
          response[material] = true;
          return null;
        });
      }
    }

    if ("orderBy" in queryString && queryString.orderBy !== "") {
      let orderBy = queryString.orderBy;
      response["orderBy"] = orderBy;
    }

    if ("brand" in queryString && queryString.brand !== "") {
      let brandArr = queryString.brand.split(",");
      if (brandArr.length > 0) {
        response["companylist"] = brandArr;
      }
    }
    return response;
  };

  getProductCoverImage(files) {
    let coverImage = productDefaultImg;
    let picArray = [];

    if (files.edges.length > 0) {
      picArray = files.edges.filter(function (itm) {
        if (itm.node.fileType === "pic" && itm.node.deleted === 0) {
          return itm.node.awsFileUrl;
        } else {
          return null;
        }
      });
    }

    if (picArray.length > 0) {
      coverImage = picArray[0].node.awsFileUrl;
    }
    return coverImage;
  }

  goToProduct(productData) {
    if (isMobile) {
      this.props.history.push({
        pathname: "/product-detail/" + productData.node.slug,
        state: { productDetail: productData.node },
      });
    } else {
      window.open("/product-detail/" + productData.node.slug, "_blank");
    }
  }

  handeCompareProductData(e, product) {
    let compareProductArr = this.state.compareProductArr;
    console.log(compareProductArr);
    if (e.target.checked) {
      compareProductArr.push(product.node);
      this.setState({ compareProductArr: compareProductArr });
    } else {
      this.setState({
        compareProductArr: this.state.compareProductArr.filter(
          (s, sidx) => product.node.productId !== s.productId
        ),
      });
    }
  }

  goToProductCompare = () => {
    if (this.state.compareProductArr.length === 0) {
      ErrorNotificationMsg("Please select product for compare.");
      return false;
    }

    this.props.history.push({
      pathname: "/compare-products",
      state: { products: this.state.compareProductArr },
    });
  };

  loadMoreProducts = () => {
    if (this.state.currentPageNo !== this.state.totalPage) {
      let filterParams = { after: this.state.nextPageId };
      let filterParameter = { ...this.state.filterParameter, ...filterParams };
      this.setState(
        { currentPageNo: this.state.currentPageNo + 1, filterParameter },
        () => {
          this.fetchProductList();
        }
      );
    }
  };

  prepareImpactSizeData(productData) {
    let impactData = [];

    if (
      productData.impactsizemodelSet &&
      productData.impactsizemodelSet.edges.length > 0
    ) {
      productData.impactsizemodelSet.edges.map((item) => {
        impactData.push({
          weight: item.node.weight,
          kgco2: item.node.kgco2,
          liter: item.node.liter,
          kmtravel: item.node.kmtravel,
          size: item.node.attributeValue ? item.node.attributeValue.value : "",
          impactsizeId: item.node.impactsizeId,
        });
        return null;
      });

      let defaultImpactSizeData = impactData.filter(function (e) {
        return e.size === "M";
      });

      if (!defaultImpactSizeData[0]) {
        defaultImpactSizeData[0] = impactData[0];
      }
      return defaultImpactSizeData[0] ? defaultImpactSizeData[0] : {};
    }
  }

  render() {
    const {
      data,
      loading,
      currentPageNo,
      totalPage,
      compareProductArr,
    } = this.state;
    return (
      <div className="container">
        <Row className="productPage">
          <ProductFilter
            onFilter={this.filterProduct}
            history={this.props.history}
            stateValues={this.state}
          />
          <Col className="product_content">
            <ProductListBlog
              impactId={
                this.props.location.state
                  ? this.props.location.state.impactId
                  : {}
              }
            />

            <div className="proList">
              <div className="title">Product List</div>

              <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                {data.length > 0 ? (
                  data.map((product, index) => {
                    let impactData = this.prepareImpactSizeData(product.node);

                    return (
                      <Col
                        xs={12}
                        sm={12}
                        md={8}
                        lg={6}
                        key={product.node.productId}
                      >
                        <div className="prowrap">
                          <div
                            className="imagdiv"
                            onClick={() => this.goToProduct(product)}
                          >
                            <img
                              alt=""
                              src={this.getProductCoverImage(
                                product.node.productfilesmodelSet
                              )}
                            ></img>
                          </div>
                          <div className="Description">
                            <h3>
                              <span onClick={() => this.goToProduct(product)}>
                                {product.node.productName}
                              </span>
                              <ProductLike productId={product.node.productId} />
                            </h3>
                            <p>{product.node.shortDescription}</p>
                          </div>
                          <ul>
                            <li>
                              <div className="size-label">
                                {impactData ? impactData.size : ""}
                              </div>
                            </li>
                            <li>
                              <div className="icondiv">
                                <img alt="icon" src={CO21}></img>
                              </div>
                              <h3>{impactData ? impactData.kgco2 : 0}</h3>
                            </li>
                            <li>
                              <div className="icondiv">
                                <img alt="icon" src={water1}></img>
                              </div>
                              <h3>{impactData ? impactData.liter : 0}</h3>
                            </li>
                            <li>
                              <div className="icondiv">
                                <img alt="icon" src={distance1}></img>
                              </div>
                              <h3>{impactData ? impactData.kmtravel : 0}</h3>
                            </li>
                          </ul>

                          <div className="price_compare_wrap">
                            <div
                              className="price"
                              onClick={() => this.goToProduct(product)}
                            >
                              € {product.node.price}
                            </div>

                            <div className="compare">
                              <Checkbox
                                onChange={(e) =>
                                  this.handeCompareProductData(e, product)
                                }
                              >
                                Add to Compare
                              </Checkbox>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                ) : data.length === 0 && loading === false ? (
                  <Col xs={24} sm={24} md={24}>
                    <div className="not-found">Not found product</div>
                  </Col>
                ) : (
                  <ProductListSkeleton />
                )}
              </Row>

              {data.length > 0 ? (
                <div className="bottomrow">
                  {compareProductArr && compareProductArr.length > 0 ? (
                    <Button
                      type="primary"
                      htmlType="button"
                      size="small"
                      className="comparebtn"
                      onClick={this.goToProductCompare}
                    >
                      COMPARE{" "}
                      <span className="compare-count">
                        {compareProductArr.length}
                      </span>
                    </Button>
                  ) : (
                    ""
                  )}

                  {currentPageNo !== totalPage ? (
                    <Button
                      type="primary"
                      htmlType="button"
                      size="small"
                      loading={loading}
                      onClick={() => this.loadMoreProducts()}
                    >
                      Load More
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withApollo(ProductList);
