/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:9ead67b1-0a57-4fdf-b937-918111646f82",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_CU3H6xzFH",
    "aws_user_pools_web_client_id": "nue1ta8bv90kqn9edmolc17hg",
    "oauth": {}
};


export default awsmobile;
