import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  Checkbox,
  Divider,
  Popconfirm,
  Select,
  Modal,
} from "antd";
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
class ProductDetail extends Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };

  onFinish = async (values) => {
    this.props.onClickNext();
  };

  handleSelectChange(name, value) {
    this.props.handleSelectChange(name, value);
  }

  renderSizeOptions = () => {
    return (
      <>
        {this.props.stateValues.sizeArr.edges &&
          this.props.stateValues.sizeArr.edges.map((attributes) => {
            return (
              <Option key={attributes.node.attributeValueId}>
                {attributes.node.value}
              </Option>
            );
          })}
      </>
    );
  };

  renderProductTypeOptions = () => {
    return (
      <>
        {this.props.stateValues.productTypeArr.edges &&
          this.props.stateValues.productTypeArr.edges.map((attributes) => {
            return (
              <Option key={attributes.node.attributeValueId}>
                {attributes.node.value}
              </Option>
            );
          })}
      </>
    );
  };

  showModal = (location) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });

  closeModal = (callback = () => {}) =>
    this.setState({ modalVisible: false }, callback);

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    if (!confirmedNavigation) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState({ confirmedNavigation: true }, () => {
          this.props.history.push(lastLocation.pathname);
        });
      }
    });

  render() {
    const { when } = this.props.stateValues;
    const { modalVisible } = this.state;
    return (
      <React.Fragment>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <Modal
          className="confirm-model"
          title={false}
          visible={modalVisible}
          onCancel={() => this.closeModal(() => {})}
          onOk={this.handleConfirmNavigationClick}
          okText="Leave"
          cancelText="Stay"
        >
          <h3>
            <ExclamationCircleOutlined /> Are you sure you want to leave?
          </h3>
          <p>
            Hey there! it looks like you have been editing something.If you
            leave before saving, your changes will be lost.
          </p>
        </Modal>

        <p>
          Please describe the details of your chosen product to register to the
          True Cost Label platform
        </p>

        <Form
          onFinish={this.onFinish}
          ref={this.props.productFormRef}
          initialValues={{
            productName: this.props.stateValues.productName,
            productType: this.props.stateValues.productType,
            shortDescription: this.props.stateValues.shortDescription,
            detailDescription: this.props.stateValues.detailDescription,
            yearlyAmountOfItemsProcured: this.props.stateValues
              .yearlyAmountOfItemsProcured,
            averageLifetimeOfYourProduct: this.props.stateValues
              .averageLifetimeOfYourProduct,
            productAccessories: this.props.stateValues.productAccessories,
            colors: this.props.stateValues.colors,
            endOfLifeOthers: this.props.stateValues.endOfLifeOthers,
            gender: this.props.stateValues.gender,
            price: this.props.stateValues.price,
            productLink: this.props.stateValues.productLink,
          }}
        >
          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}>
              <label>Product Name * </label>
              <Form.Item
                name="productName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input product name!",
                  },
                ]}
              >
                <Input
                  placeholder="Product name"
                  onChange={this.props.handleInputChange("productName")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <label>Type of Fashion Product</label>
              <Select
                showSearch
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                size="large"
                placeholder="Please select type of product"
                onChange={this.props.handleMultiSelectChange("productType")}
                value={this.props.stateValues.productType}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.renderProductTypeOptions()}
              </Select>
            </Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}>
              <label>Product Accessories</label>
              <Form.Item name="productAccessories">
                <Input
                  placeholder="Example: 2 aluminium zippers (10 grams each), 1 cotton cord (5 grams)"
                  onChange={this.props.handleInputChange("productAccessories")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <label>Available colours/variations</label>
              <Form.Item name="colors">
                <Input
                  placeholder="red/yellow/blue/stripes"
                  onChange={this.props.handleInputChange("colors")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}>
              <label>Product weight per size *</label>
              <Form.Item>
                {this.props.stateValues.weightSizeArr.map((x, index) => {
                  return (
                    <Row gutter={[10]} className="addrow" key={index}>
                      <Col xs={12} sm={12} lg={12}>
                        <Form.Item name={`attributeValueId` + index}>
                          <Select
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Please select available sizes"
                            onChange={(value) => {
                              this.props.handleCloneSelectChange(value, index);
                            }}
                            value={
                              x.attributeValue
                                ? x.attributeValue.attributeValueId
                                : ""
                            }
                          >
                            {this.renderSizeOptions()}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={6} sm={6} lg={6}>
                        <Form.Item
                          name={`sizeWeight` + index}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input weight!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="grams"
                            name="sizeWeight"
                            onChange={(e) =>
                              this.props.handleWeightSize(e, index)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={6} sm={6} lg={6}>
                        {index !== 0 ? (
                          <Popconfirm
                            className="action"
                            title="Are you sure delete ?"
                            onConfirm={(id) =>
                              this.props.removeWeightSize(index, x)
                            }
                            okText="Yes"
                            placement="left"
                          >
                            <Button className="close" size="small">
                              <CloseCircleOutlined />
                            </Button>
                          </Popconfirm>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  );
                })}

                <Button
                  size="small"
                  onClick={(e) => this.props.addWeightSize(e)}
                >
                  <PlusCircleOutlined /> Add Fields
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Row gutter={[15]}>
                <Col xs={12} sm={12} lg={12}>
                  <label>Product Packaging</label>
                  <Form.Item name="averageLifetimeOfYourProduct">
                    <Input
                      placeholder="Cardboard box 200 grams"
                      onChange={this.props.handleInputChange(
                        "averageLifetimeOfYourProduct"
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} lg={12}>
                  <label>Yearly amount of items produced *</label>
                  <Form.Item
                    name="yearlyAmountOfItemsProcured"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message:
                          "Please input yearly amount of items produced!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="1000 items"
                      onChange={this.props.handleInputChange(
                        "yearlyAmountOfItemsProcured"
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={24}>
                  <label>Short title description *</label>
                  <Form.Item
                    name="shortDescription"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input short title description!",
                      },
                    ]}
                  >
                    <TextArea
                      onChange={this.props.handleInputChange(
                        "shortDescription"
                      )}
                      placeholder="Write short title description here"
                      autoSize={{ minRows: 2 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}>
              <label>Material composition of your product *</label>
              <Form.Item>
                {this.props.stateValues.compositions.map((x, index) => {
                  return (
                    <Row gutter={[10]} className="addrow" key={index}>
                      <Col xs={12} sm={12} lg={12}>
                        <Form.Item
                          name={`materialName` + index}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input material!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Material"
                            name="materialName"
                            onChange={(e) =>
                              this.props.handleComposition(e, index)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={6} sm={6} lg={6}>
                        <Form.Item
                          name={`percentage` + index}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input percentage!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="%"
                            name="percentage"
                            onChange={(e) =>
                              this.props.handleComposition(e, index)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={6} sm={6} lg={6}>
                        {index !== 0 ? (
                          <Popconfirm
                            className="action"
                            title="Are you sure delete ?"
                            onConfirm={(id) =>
                              this.props.removeComposition(index, x)
                            }
                            okText="Yes"
                            placement="left"
                          >
                            <Button className="close" size="small">
                              <CloseCircleOutlined />
                            </Button>
                          </Popconfirm>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  );
                })}

                <Button
                  size="small"
                  onClick={(e) => this.props.addComposition(e)}
                >
                  <PlusCircleOutlined /> Add Fields
                </Button>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={12}>
              <Row gutter={[15]}>
                <Col xs={24} sm={24} lg={24}>
                  <label>Detail description of your product *</label>
                  <Form.Item
                    name="detailDescription"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input product detail description!",
                      },
                    ]}
                  >
                    <TextArea
                      onChange={this.props.handleInputChange(
                        "detailDescription"
                      )}
                      placeholder="Write detail description here"
                      autoSize={{ minRows: 4 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={6}>
              <label>Gender *</label>
              <Form.Item
                name="gender"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please select gender!",
                  },
                ]}
              >
                <Select
                  onChange={(e) => this.handleSelectChange("gender", e)}
                  style={{ width: "98%" }}
                  size="medium"
                >
                  <Option value="Male">Men</Option>
                  <Option value="Female">Women</Option>
                  <Option value="Unisex">Unisex</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <label>Price *</label>
              <Form.Item
                name="price"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      "^[0-9]?((,[0-9]+)|([0-9]+(,[0-9]+)?))$"
                    ),
                    message: "Please input valid price!",
                  },
                ]}
              >
                <Input
                  placeholder="Price"
                  onChange={this.props.handleInputChange("price")}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={12}>
              <label>Product Link *</label>
              <Form.Item
                name="productLink"
                rules={[
                  {
                    required: true,
                    type: "url",
                    message: "Please input valid product link!",
                  },
                ]}
              >
                <Input
                  placeholder="Product Link"
                  onChange={this.props.handleInputChange("productLink")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={24} lg={24}>
              <label className="tick">
                Please tick which of the following boxes apply for the end of
                life of your product
              </label>
            </Col>
          </Row>

          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange(
                  "endOfLifeRecyclable"
                )}
                checked={this.props.stateValues.endOfLifeRecyclable}
              >
                Recyclable
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange(
                  "endOfLifeBiodegradable"
                )}
                checked={this.props.stateValues.endOfLifeBiodegradable}
              >
                Biodegradable
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange(
                  "endOfLifeProductAsAService"
                )}
                checked={this.props.stateValues.endOfLifeProductAsAService}
              >
                Product as a service
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange(
                  "endOfLifeRepairServiceBrand"
                )}
                checked={this.props.stateValues.endOfLifeRepairServiceBrand}
              >
                Repair service brand
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange("biobasedMaterial")}
                checked={this.props.stateValues.biobasedMaterial}
              >
                Bio based material
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange(
                  "refurbishedMaterial"
                )}
                checked={this.props.stateValues.refurbishedMaterial}
              >
                Refurbished material
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange("reusedMaterial")}
                checked={this.props.stateValues.reusedMaterial}
              >
                Reused material
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange("recycledMaterial")}
                checked={this.props.stateValues.recycledMaterial}
              >
                Recycled material
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange("vegan")}
                checked={this.props.stateValues.vegan}
              >
                Vegan
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange("secondLifePossible")}
                checked={this.props.stateValues.secondLifePossible}
              >
                Second life possible
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={8} xl={6}>
              <Checkbox
                onChange={this.props.handleCheckboxChange("organicFiber")}
                checked={this.props.stateValues.organicFiber}
              >
                Organic fibre use
              </Checkbox>
            </Col>
            <Col xs={24} sm={12} lg={10}>
              <Form.Item name="endOfLifeOthers" className="other">
                <Input
                  placeholder="other...type answer"
                  onChange={this.props.handleInputChange("endOfLifeOthers")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <Row gutter={[15]} justify="space-between" className="bottomButtons">
            <Col>
              <Button
                type="secondary"
                htmlType="button"
                size="large"
                onClick={() => this.props.history.push("/seller-get-support")}
              >
                ASK SUPPORT
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={this.props.stateValues.btnLoading}
              >
                NEXT
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default withApollo(ProductDetail);
