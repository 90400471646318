import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { Col, Row, Layout, Button, Breadcrumb } from "antd";
import { RightOutlined } from "@ant-design/icons";
import Sidebar from "../common/Sidebar";
import ReportMediaSkeleton from "./ReportMediaSkeleton";
import { authUserData } from "../../../utils/Helpers";

import "../dashboard/Dashboard.scss";
import "../common/SellerPages.scss";

import productDefaultImg from "../../../images/noimg-product.jpg";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { PRODUCT_LIST_QUERY } from "../../brand/product/ProductQuery";

const { Content } = Layout;
class ReportMedia extends Component {
  state = {
    loading: false,
    orderBy: "-product_id",
    data: [],
    perPageRecord: 4,
    authUserId: authUserData().appusersId,
  };

  componentDidMount() {
    this.fetchProductList({
      deleted: 0,
      status: true,
      orderBy: this.state.orderBy,
      userId: authUserData().appusersId,
      first: this.state.perPageRecord,
    });
  }

  fetchProductList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: PRODUCT_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data:
            this.state.data.length > 0
              ? this.state.data.concat(result.data.allProduct.edges)
              : result.data.allProduct.edges,
          nextPageId: result.data.allProduct.pageInfo.endCursor,
          totalRecords: result.data.allProduct.filterCount,
          totalPage: Math.ceil(
            result.data.allProduct.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  getProductCoverImage(files) {
    let coverImage = productDefaultImg;
    let picArray = [];

    if (files.edges.length > 0) {
      picArray = files.edges.filter(function (itm) {
        if (itm.node.fileType === "pic" && itm.node.deleted === 0) {
          return itm.node.awsFileUrl;
        } else {
          return null;
        }
      });
    }

    if (picArray.length > 0) {
      coverImage = picArray[0].node.awsFileUrl;
    }
    return coverImage;
  }

  goToProduct(productData) {
    this.props.history.push({
      pathname: "/seller-product-document/" + btoa(productData.node.productId),
      state: { productDetail: productData.node },
    });
  }

  render() {
    const { data, loading } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Report & Media
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Reports & Media</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>My Products</h3>
                      </Col>
                    </Row>
                  </div>
                  <div className="listpro">
                    <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}>
                      {data.length > 0 ? (
                        data.map((product, index) => {
                          return (
                            <Col xs={24} sm={12} lg={8} xl={6}>
                              <div className="imgD">
                                <img
                                  src={this.getProductCoverImage(
                                    product.node.productfilesmodelSet
                                  )}
                                  alt="product-1"
                                ></img>
                              </div>
                              <div className="procontent">
                                <h3>{product.node.productName} </h3>
                                <p>See LCA report</p>
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  onClick={() => this.goToProduct(product)}
                                >
                                  Add / Revise Uploaded Media{" "}
                                  <RightOutlined align="end" />
                                </Button>
                              </div>
                            </Col>
                          );
                        })
                      ) : data.length === 0 && loading === false ? (
                        <div
                          className="not-found"
                          style={{ marginBottom: "20px", padding: "30px" }}
                        >
                          Not found product
                        </div>
                      ) : (
                        <ReportMediaSkeleton />
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ReportMedia);
