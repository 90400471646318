import React, { Component } from "react";
import jsp from "../../../images/jasper.png";
export class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="container">
        <div className="howwedo">
          <h1>HOW WE GENERATE THE TRUE COSTS OF YOUR PRODUCT</h1>
          <div className="normal_div">
            All LCAs made by True Cost Label B.V. including the data and methods
            contained within are calculated using our own developed tool, the
            ‘True Cost Generator’. A custom LCA tool built by True Cost Label,
            specifically for Fashion LCAs and the detailed supply chains of the
            fashion industry.
          </div>
          <ul className="mainul">
            <li>
              <div className="count">1</div>
              <h3>Software, Databases and Methodology applied. </h3>
              <p>
                We apply OpenLCA[1] software to access input data for the True
                Cost Generator, with data mainly but not exclusively deriving
                from the following databases:
                <br />
                Ecoinvent 3.6 [2];
                <br />
                Idemat 2021 by TU Delft [3];.
              </p>
            </li>
            <li>
              <div className="count">2</div>
              <h3>
                We apply the following Impact Assessment Methods for data
                retrieved and used in our LCAs
              </h3>
              <p>
                A) Carbon footprint: IPCC 2013 GWP 100a [4] as recommended by
                the European Platform on Life Cycle Assessment: ILCD [5]
                (International Reference Life Cycle Data System and the
                Greenhouse Gas Protocol
                <br />
                B) Water Depletion: ILCD 2011; Resource depletion - water;
                midpoint; freshwater scarcity; Swiss Ecoscarcity 2006. <br />
                C) Total distance in kilometer and mode of transport: Supply
                chain data provided by the customer in combination with Google
                maps and Sea Distances. <br />
                <br />
                In addition, LCA data is included from carefully selected LCAs
                from peer reviewed scientific papers. This is mostly done for
                innovative textile production processes or processes poorly
                modelled in existing databases. Assumptions made for these
                additions are stated in detail in each report
              </p>
            </li>
            <li>
              <div className="count">3</div>
              <h3>Goal and scope</h3>
              <p>
                We calculate our LCAs with a functional unit of total impact per
                kg of product from the raw materials to the manufacturing of the
                product with all transport processes included. (Cradle-to-Gate).
              </p>
            </li>
            <li>
              <div className="count">4</div>
              <h3>Standardization </h3>
              <p>
                True Cost Label Applies the ILCD method for its impact numbers,
                which is standardized according to EU-PEF method: European
                Product Environmental Footprint (EC, 2018. Product environmental
                footprint category rules, version 6.3). Our LCAs, LCA Reports
                and advise given based on LCA results follow the general
                principles of the ISO14044 quality standard for Life Cycle
                Assessment
              </p>
            </li>
            <li>
              <div className="count">5</div>
              <h3>References: </h3>
              <p>
                1. https://www.openlca.org/ <br />
                2.
                https://www.ecoinvent.org/database/older-versions/ecoinvent-36/ecoinvent-36.html
                <br />
                3. https://www.ecocostsvalue.com/EVR/model/theory/5-Idemat.html
                <br />
                4. https://www.ipcc.ch/
                <br />
                5.
                https://eplca.jrc.ec.europa.eu/uploads/ILCD-Recommendation-of-methods-for-LCIA-def.pdf
                <br />
                6. https://www.iso.org/standard/38498.html
              </p>
            </li>
          </ul>
        </div>
        <div className="anyqa">
          <div className="lc">
            <h3>ANY QUESTIONS ABOUT THIS?</h3>
            <p>
              For questions related to our methods, calculations and numbers
              provided on the website, please take up contact with our LCA
              expert.
              <br />
              <br />
              <br />
              Tel: (+31) 6 31 69 73 59 <br />
              E-mail: Jasper@truecostlabel.com
            </p>
          </div>
          <div className="rc">
            <img src={jsp} alt="jsp"></img>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
