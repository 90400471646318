import React, { Component } from "react";
import { Col, Skeleton } from "antd";
export class ProductListSkeleton extends Component {
  state = {
    active: false,
    size: "default",
    buttonShape: "default",
    avatarShape: "circle",
    colArr: ["1", "2", "3", "4"],
  };

  render() {
    const { buttonShape, colArr } = this.state;
    return (
      <>
        {colArr.length > 0 &&
          colArr.map((book) => (
            <Col xs={24} sm={12} md={8} lg={6} key={book}>
              <div className="prowrap" style={{ width: "100%" }}>
                <Skeleton.Image className="skeleton-img" />

                <Skeleton.Button
                  active
                  className="skeleton20"
                  shape={buttonShape}
                ></Skeleton.Button>

                <Skeleton.Button
                  active
                  className="skeleton20"
                  shape={buttonShape}
                ></Skeleton.Button>

                <Skeleton.Button
                  active
                  className="skeleton50"
                  shape={buttonShape}
                ></Skeleton.Button>

                <Skeleton.Button
                  active
                  className="skeleton30"
                  shape={buttonShape}
                ></Skeleton.Button>

                <Skeleton.Button
                  active
                  className="skeleton50"
                  shape={buttonShape}
                ></Skeleton.Button>

                <Skeleton.Button
                  active
                  className="skeleton30"
                  shape={buttonShape}
                ></Skeleton.Button>
              </div>
            </Col>
          ))}
      </>
    );
  }
}

export default ProductListSkeleton;
