import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Tabs, Space } from "antd";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { ShowTextByCharLength } from "../../../utils/Helpers";
import { useHistory } from "react-router-dom";

import cloud from "../../../images/cloud.png";
import recycle from "../../../images/recycle.png";
import bio from "../../../images/bio.png";
import time from "../../../images/time.png";
import water from "../../../images/water.png";
import world from "../../../images/world.png";
import service from "../../../images/service.png";

import cloudBlack from "../../../images/black-icons/cloud-b.png";
import recycleBlack from "../../../images/black-icons/recycle-b.png";
import bioBlack from "../../../images/black-icons/bio-b.png";
import timeBlack from "../../../images/black-icons/time-b.png";
import waterBlack from "../../../images/black-icons/water-b.png";
import worldBlack from "../../../images/black-icons/world-b.png";
import serviceBlack from "../../../images/black-icons/service-b.png";

import { GET_IMPACT_INDICATOR_QUERY } from "../../admin/impactIndicator/ImpactIndicatorQuery";

const { TabPane } = Tabs;

const createMarkup = (data) => {
  return { __html: data };
};

const CommonContent = (props) => {
  const history = useHistory();
  return (
    <React.Fragment>
      {props.title !== "" ? (
        <div className="water_usages">
          <div className="water_title">
            <div className="iconbox">
              <img className="cardicon" alt="icon" src={props.tabIcon}></img>
            </div>
            <div>
              <h3>{props.title}</h3>
            </div>
          </div>
          <Row>
            <Col flex="auto" className="l_col">
              <span
                dangerouslySetInnerHTML={createMarkup(
                  ShowTextByCharLength(props.description, 460)
                )}
              ></span>
              <div className="headerTabButtons">
                <div>
                  <Space>
                    <Button
                      type="primary"
                      htmlType="button"
                      className="blkbtn"
                      onClick={() =>
                        history.push("/impact-indicator", {
                          impactId: props.impactIndicatorId,
                        })
                      }
                    >
                      Keep Reading
                    </Button>
                    <Button
                      type="primary"
                      htmlType="button"
                      className="blkbtn"
                      onClick={() => props.onsetTab("0")}
                    >
                      Close
                    </Button>
                  </Space>
                </div>
                <Button
                  type="primary"
                  htmlType="button"
                  className="blkbtn"
                  onClick={() =>
                    history.push(props.shopNowUrl, {
                      impactId: props.impactIndicatorId,
                    })
                  }
                >
                  Start Shopping
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const DefaultCommonContent = (props) => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="normal_text">
        Use the guide above to discover the indicators we use to calculate the
        impact of the products you want to wear
      </div>
      <div className="btn_row_1">
        <Button
          type="primary"
          htmlType="submit"
          className="blkbtn"
          onClick={() => history.push("/products")}
        >
          Start Shopping
        </Button>
      </div>
    </React.Fragment>
  );
};

export class HeaderTab extends Component {
  state = {
    apiLoading: false,
    activeTabKey: "0",
    isLogoClick: false,
    data: [],
    excludeRoute: [
      "/login",
      "/brand-register",
      "/forgot-password",
      "/verify-account",
      "/about-us",
    ],
  };

  componentDidMount() {
    this.getImpactIndicator({ deleted: 0, orderBy: "impact_indicator_id" });
  }

  componentDidUpdate(prevProps) {
    let currentRoute = this.props.location.pathname;
    if (currentRoute !== this.state.currentRoute) {
      this.setState({
        currentRoute,
      });
    }

    if (this.props.isLogoClick !== this.state.isLogoClick) {
      this.setState({
        activeTabKey: "0",
        isLogoClick: this.props.isLogoClick,
      });
    }
  }

  getImpactIndicator = (params) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;

    client
      .query({
        query: GET_IMPACT_INDICATOR_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          apiLoading: false,
          data: result.data.allImpactIndicator.edges,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleTabCallback = (tabKey) => {
    this.setState(
      { activeTabKey: tabKey, isLogoClick: false },
      this.props.resetIsLogoClick()
    );
  };

  setTab = (tabKey) => {
    this.setState({ activeTabKey: tabKey });
  };

  render() {
    const { data, currentRoute, excludeRoute } = this.state;
    if (excludeRoute.includes(currentRoute)) return null;

    return (
      <section className="tabsection">
        {data.length > 0 ? (
          <div className="container container_small">
            <Row>
              <Col
                flex="auto"
                xs={{ order: 3 }}
                lg={{ order: 2 }}
                className="tabcol"
              >
                <div className="ipact_ind">
                  <div className="tabhead">Impact Indicators</div>
                  <Tabs
                    activeKey={this.state.activeTabKey}
                    onChange={this.handleTabCallback}
                  >
                    <TabPane
                      tab={
                        <span>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={cloud}
                          ></img>
                        </span>
                      }
                      key="0"
                    >
                      {currentRoute !== "/products" ? (
                        <DefaultCommonContent />
                      ) : (
                        ""
                      )}
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={cloud}
                          ></img>
                        </span>
                      }
                      key="1"
                    >
                      <CommonContent
                        {...data[0].node}
                        tabIcon={cloudBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={recycle}
                          ></img>
                        </span>
                      }
                      key="2"
                    >
                      <CommonContent
                        {...data[1].node}
                        tabIcon={recycleBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <img className="cardicon" alt="icon" src={bio}></img>
                        </span>
                      }
                      key="3"
                    >
                      <CommonContent
                        {...data[2].node}
                        tabIcon={bioBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <img className="cardicon" alt="icon" src={time}></img>
                        </span>
                      }
                      key="4"
                    >
                      <CommonContent
                        {...data[3].node}
                        tabIcon={timeBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={water}
                          ></img>
                        </span>
                      }
                      key="5"
                    >
                      <CommonContent
                        {...data[4].node}
                        tabIcon={waterBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={world}
                          ></img>
                        </span>
                      }
                      key="6"
                    >
                      <CommonContent
                        {...data[5].node}
                        tabIcon={worldBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={service}
                          ></img>
                        </span>
                      }
                      key="7"
                    >
                      <CommonContent
                        {...data[6].node}
                        tabIcon={serviceBlack}
                        onsetTab={this.setTab}
                      />
                    </TabPane>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
      </section>
    );
  }
}

export default withApollo(withRouter(HeaderTab));
