import React, { Component } from "react";
import { Col, Checkbox, Form, Button, Drawer, Radio } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import qs from "query-string";
import FilterBrandList from "./FilterBrandList";
import FilterSizeCategoryList from "./FilterSizeCategoryList";

export class ProductFilter extends Component {
  state = { visible: false };

  componentDidMount() {
    let searchString = qs.parse(this.props.history.location.search);
    this.ShowFilterSelected(searchString);
  }

  ShowFilterSelected = (searchString) => {
    let queryStringGender =
      searchString.gender !== undefined ? searchString.gender.split(",") : [];

    let orderByVal =
      searchString.orderBy !== undefined ? searchString.orderBy : "";

    let queryStringCategories =
      searchString.category !== undefined
        ? searchString.category.split(",")
        : [];

    let queryStringBrands =
      searchString.brand !== undefined ? searchString.brand.split(",") : [];

    let queryStringSize =
      searchString.size !== undefined ? searchString.size.split(",") : [];

    let queryStringMaterial =
      searchString.material !== undefined
        ? searchString.material.split(",")
        : [];

    let finalQueryStringArr = queryStringCategories.concat(
      queryStringGender,
      queryStringBrands,
      queryStringSize,
      queryStringMaterial
    );

    let SelectedFilters = {};
    if (finalQueryStringArr.length > 0) {
      finalQueryStringArr.map((filterName) => {
        SelectedFilters[filterName] = "checked";
        return null;
      });
      this.setState({ ...SelectedFilters, orderBy: orderByVal });
    } else if (orderByVal && orderByVal !== "") {
      this.setState({ orderBy: orderByVal });
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleGenderFilter = (input) => (event) => {
    let searchString = qs.parse(this.props.history.location.search);
    let genderValue =
      searchString.gender !== undefined ? searchString.gender : "";
    let genderObj = {};

    if (event.target.checked) {
      genderObj.gender = genderValue !== "" ? genderValue + "," + input : input;
      this.setState({ [input]: event.target.checked });
    } else {
      let arr = genderValue.split(",");
      const index = arr.indexOf(input);
      if (index > -1) {
        arr.splice(index, 1);
      }
      genderObj.gender = arr.toString();
      this.setState({ [input]: undefined });
    }

    let updatedSearchString = { ...searchString, ...genderObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this.props.onFilter(this.state);
  };

  handleCategoryFilter = (input) => (event) => {
    let searchString = qs.parse(this.props.history.location.search);
    let categoryValue =
      searchString.category !== undefined ? searchString.category : "";
    let categoryObj = {};

    if (event.target.checked) {
      categoryObj.category =
        categoryValue !== "" ? categoryValue + "," + input : input;
      this.setState({ [input]: event.target.checked });
    } else {
      let arr = categoryValue.split(",");
      const index = arr.indexOf(input);
      if (index > -1) {
        arr.splice(index, 1);
      }
      categoryObj.category = arr.toString();
      this.setState({ [input]: undefined });
    }

    let updatedSearchString = { ...searchString, ...categoryObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this.props.onFilter(this.state);
  };

  handleBrandFilter = (input) => (event) => {
    let searchString = qs.parse(this.props.history.location.search);
    let brandValue = searchString.brand !== undefined ? searchString.brand : "";
    let brandObj = {};

    if (event.target.checked) {
      brandObj.brand = brandValue !== "" ? brandValue + "," + input : input;
      this.setState({ [input]: event.target.checked });
    } else {
      let arr = brandValue.split(",");
      const index = arr.indexOf(input);
      if (index > -1) {
        arr.splice(index, 1);
      }
      brandObj.brand = arr.toString();
      this.setState({ [input]: undefined });
    }

    let updatedSearchString = { ...searchString, ...brandObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this.props.onFilter(this.state);
  };

  handleSizeFilter = (input) => (event) => {
    let searchString = qs.parse(this.props.history.location.search);
    let sizeValue = searchString.size !== undefined ? searchString.size : "";
    let sizeObj = {};

    if (event.target.checked) {
      sizeObj.size = sizeValue !== "" ? sizeValue + "," + input : input;
      this.setState({ [input]: event.target.checked });
    } else {
      let arr = sizeValue.split(",");
      const index = arr.indexOf(input);
      if (index > -1) {
        arr.splice(index, 1);
      }
      sizeObj.size = arr.toString();
      this.setState({ [input]: undefined });
    }

    let updatedSearchString = { ...searchString, ...sizeObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this.props.onFilter(this.state);
  };

  handleMaterialChange = (input) => (event) => {
    let searchString = qs.parse(this.props.history.location.search);
    let materialValue =
      searchString.material !== undefined ? searchString.material : "";
    let materialObj = {};

    if (event.target.checked) {
      materialObj.material =
        materialValue !== "" ? materialValue + "," + input : input;
      this.setState({ [input]: event.target.checked });
    } else {
      let arr = materialValue.split(",");
      const index = arr.indexOf(input);
      if (index > -1) {
        arr.splice(index, 1);
      }
      materialObj.material = arr.toString();
      this.setState({ [input]: undefined });
    }

    let updatedSearchString = { ...searchString, ...materialObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this.props.onFilter(this.state);
  };

  handleImpactSizeChange = (event) => {
    let searchString = qs.parse(this.props.history.location.search);
    let impactValue =
      searchString.impact !== undefined ? searchString.impact : "";
    let impactObj = {};

    if (event.target.value) {
      impactObj.orderBy = impactValue !== "" ? impactValue : event.target.value;
      this.setState({ orderBy: event.target.value });
    }

    let updatedSearchString = { ...searchString, ...impactObj };
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams(updatedSearchString).toString(),
      state: {
        propsData: this.props.history.location.state
          ? this.props.history.location.state.propsData
          : "",
      },
    });
    this.props.onFilter(this.state);
  };

  renderGender() {
    return (
      <React.Fragment>
        <Form>
          <Form.Item>
            <Checkbox
              onChange={this.handleGenderFilter("Male")}
              checked={this.state.Male}
            >
              Men
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              onChange={this.handleGenderFilter("Female")}
              checked={this.state.Female}
            >
              Women
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              onChange={this.handleGenderFilter("Unisex")}
              checked={this.state.Unisex}
            >
              Unisex
            </Checkbox>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }

  renderBrand() {
    return (
      <FilterBrandList
        handleBrandFilter={(e) => this.handleBrandFilter(e)}
        stateValue={this.state}
      />
    );
  }

  renderMaterials() {
    return (
      <Form>
        <Form.Item>
          <Checkbox
            onChange={this.handleMaterialChange("biobasedMaterial")}
            checked={this.state.biobasedMaterial}
          >
            Bio based material
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={this.handleMaterialChange("refurbishedMaterial")}
            checked={this.state.refurbishedMaterial}
          >
            Refurbished material
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={this.handleMaterialChange("recycledMaterial")}
            checked={this.state.recycledMaterial}
          >
            Recycled material
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={this.handleMaterialChange("vegan")}
            checked={this.state.vegan}
          >
            Vegan fibers
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={this.handleMaterialChange("organicFiber")}
            checked={this.state.organicFiber}
          >
            Organic fiber
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={this.handleMaterialChange("endOfLifeProductAsAService")}
            checked={this.state.endOfLifeProductAsAService}
          >
            Product as a service
          </Checkbox>
        </Form.Item>
      </Form>
    );
  }

  renderImpactSize() {
    return (
      <div className="impact-filter-div">
        <Radio.Group
          onChange={this.handleImpactSizeChange}
          value={this.state.orderBy}
        >
          <Radio value="kgco2">CO2 emissions (kg)</Radio>
          <Radio value="liter">Water use (L)</Radio>
          <Radio value="kmtravel">Distance (km)</Radio>
        </Radio.Group>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Col className="filter_left">
          <div className="category_one">
            <h3>Gender</h3>
            {this.renderGender()}
          </div>
          <div className="category_one">
            <h3>Impact Indicators</h3>
            {this.renderImpactSize()}
          </div>
          <FilterSizeCategoryList
            handleCategoryFilter={(e) => this.handleCategoryFilter(e)}
            handleSizeFilter={(e) => this.handleSizeFilter(e)}
            stateValue={this.state}
          />
          <div className="category_one">
            <h3>Filters</h3>
            {this.renderMaterials()}
          </div>
          <div className="category_one">
            <h3>Brands</h3>
            {this.renderBrand()}
          </div>
        </Col>
        <Col className="filter_left_mob">
          <div className="site-drawer-render-in-current-wrapper ">
            <Button className="filterbtn" type="link" onClick={this.showDrawer}>
              <FilterOutlined />
            </Button>

            <Drawer
              placement="left"
              mask={false}
              onClose={this.onClose}
              visible={this.state.visible}
              getContainer={false}
            >
              <div className="category_one">
                <h3>Gender</h3>
                {this.renderGender()}
              </div>
              <div className="category_one">
                <h3>Impact Indicators</h3>
                {this.renderImpactSize()}
              </div>
              <FilterSizeCategoryList
                handleCategoryFilter={(e) => this.handleCategoryFilter(e)}
                handleSizeFilter={(e) => this.handleSizeFilter(e)}
                stateValue={this.state}
              />
              <div className="category_one">
                <h3>Filters</h3>
                {this.renderMaterials()}
              </div>
              <div className="category_one">
                <h3>Brands</h3>
                {this.renderBrand()}
              </div>
            </Drawer>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default ProductFilter;
