import React, { Component } from "react";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Input,
  Space,
  Breadcrumb,
} from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData } from "../../../utils/Helpers";

import Sidebar from "../common/Sidebar";

import "../dashboard/Dashboard.scss";
import "../common/SellerPages.scss";

import jasper from "../../../images/jasper.png";
import vincenzo from "../../../images/vinsenzo.png";
import samantha from "../../../images/samantha.jpg";

const { TextArea } = Input;
const { Content } = Layout;

export const CREATE_GET_SUPPORT_QUERY = gql`
  mutation createGetsupport(
    $authUserId: Int!
    $supportContent: String!
    $status: String!
  ) {
    createGetsupport(
      input: {
        userId: $authUserId
        supportContent: $supportContent
        status: $status
      }
    ) {
      getsupport {
        getsupportId
        id
        supportContent
      }
    }
  }
`;
class GetSupport extends Component {
  state = {
    btnLoading: false,
    authUserId: authUserData().appusersId,
    status: "pending",
  };
  formRef = React.createRef();

  onFinish = async (values) => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    await client
      .mutate({
        mutation: CREATE_GET_SUPPORT_QUERY,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg(
          "Thank you!",
          "Admin team will revert on it soon."
        );
        this.formRef.current.resetFields();
        this.setState({ btnLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Get Support
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Get Support</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="quotewraper">
                  <Form ref={this.formRef} onFinish={this.onFinish}>
                    <Row>
                      <Col xs={24} sm={24} lg={24}>
                        <label>
                          Dear Customer, Please describe what you would like to
                          request support for: *
                        </label>
                        <p>
                          A True Cost Label Representative will take up contact
                          with you quickly to service your request{" "}
                        </p>

                        <Form.Item
                          name="supportContent"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input description!",
                            },
                          ]}
                        >
                          <TextArea
                            placeholder="Write Description here: "
                            autoSize={{ minRows: 4 }}
                            onChange={this.handleInputChange("supportContent")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider />
                    <Row
                      gutter={[15]}
                      align="end"
                      justify="space-between"
                      className="bottomButtons"
                    >
                      <Col></Col>
                      <Col>
                        <Space>
                          <Button
                            type="secondary"
                            htmlType="submit"
                            size="large"
                            onClick={this.props.history.goBack}
                          >
                            BACK
                          </Button>

                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={this.state.btnLoading}
                          >
                            SUBMIT
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div className="contectwraper">
                  <div className="heading">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>Contact Us </h3>
                      </Col>
                    </Row>
                  </div>
                  <div className="owners">
                    <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}>
                      <Col xs={24} sm={12} lg={8} xl={6}>
                        <div className="imgD">
                          <img src={jasper} alt="Jasper"></img>
                        </div>
                        <div className="ownersdetail">
                          <h3>Jasper Roosendaal</h3>
                          <h5>LCA & Supplychain </h5>
                          <Link to="#" className="contact-no">
                            <PhoneOutlined /> +316 31 69 73 69
                          </Link>
                          <Link to="#" className="contact-no">
                            <MailOutlined /> Jasper@truecostlabel.com
                          </Link>
                        </div>
                      </Col>
                      <Col xs={24} sm={12} lg={8} xl={6}>
                        <div className="imgD">
                          <img src={vincenzo} alt="Vincenzo"></img>
                        </div>
                        <div className="ownersdetail">
                          <h3>Vincenzo Fornoni</h3>
                          <h5>Platform & Analytics </h5>
                          <Link to="#" className="contact-no">
                            <PhoneOutlined /> +316 30 37 12 87{" "}
                          </Link>
                          <Link to="#" className="contact-no">
                            <MailOutlined /> vincenzo@truecostlabel.com
                          </Link>
                        </div>
                      </Col>
                      <Col xs={24} sm={12} lg={8} xl={6}>
                        <div className="imgD">
                          <img src={samantha} alt="Samantha"></img>
                        </div>
                        <div className="ownersdetail">
                          <h3>Samantha Koch</h3>
                          <h5>Brand Director </h5>
                          <Link to="#" className="contact-no">
                            <PhoneOutlined /> +316 50 46 25 30{" "}
                          </Link>
                          <Link to="#" className="contact-no">
                            <MailOutlined /> samantha@truecostlabel.com
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(GetSupport);
