import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { debounce } from "lodash";
import moment from "moment";
import {
  Col,
  Row,
  Layout,
  Button,
  Table,
  Input,
  Space,
  Breadcrumb,
  Popconfirm,
  Select,
  Tooltip,
  Badge,
} from "antd";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import "../dashboard/Dashboard.scss";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData } from "../../../utils/Helpers";
import Sidebar from "../common/Sidebar";
import {
  BRAND_PRODUCT_LIST_QUERY,
  CHANGE_PRODUCT_STATUS_QUERY,
  DELETE_PRODUCT_QUERY,
} from "./ProductQuery";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
class ManageProduct extends Component {
  state = {
    data: [],
    loading: false,
    popConfirmShowStatus: false,
    editIndexStatus: null,
    perPageRecord: 20,
    nextPageId: "",
    previousPageId: "",
    currentPageNo: 1,
    totalPage: 1,
    totalRecords: 0,
    orderBy: "-product_id",
    authUserId: authUserData().appusersId,
    filter: { deleted: 0, userId: authUserData().appusersId },
    month: moment().format("M"),
    year: moment().format("YYYY"),
  };

  componentDidMount() {
    this.fetchProductList({
      deleted: 0,
      orderBy: this.state.orderBy,
      userId: authUserData().appusersId,
      first: this.state.perPageRecord,
      month: this.state.month,
      year: this.state.year,
    });

    this.searchCallback = debounce(function (e) {
      this.searchProduct(e.target.value);
    }, 500);
  }

  handleTableChange = (pagination, filters, sorter) => {
    const orderBy = this.GetSortingEnumValue(sorter.columnKey, sorter.order);
    this.setState({ orderBy: orderBy, currentPageNo: 1 });

    this.fetchProductList({
      deleted: 0,
      userId: authUserData().appusersId,
      orderBy: orderBy,
      first: this.state.perPageRecord,
      month: this.state.month,
      year: this.state.year,
    });
  };

  fetchProductList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: BRAND_PRODUCT_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data: result.data.allProduct.edges,
          nextPageId: result.data.allProduct.pageInfo.endCursor,
          previousPageId: result.data.allProduct.pageInfo.startCursor,
          totalRecords: result.data.allProduct.filterCount,
          totalPage: Math.ceil(
            result.data.allProduct.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  nextPage = (params = {}) => {
    this.fetchProductList({
      deleted: 0,
      userId: authUserData().appusersId,
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
      after: this.state.nextPageId,
      month: this.state.month,
      year: this.state.year,
    });

    if (this.state.currentPageNo !== this.state.totalPage) {
      this.setState({ currentPageNo: this.state.currentPageNo + 1 });
    }
  };

  previousPage = (params = {}) => {
    this.fetchProductList({
      deleted: 0,
      userId: authUserData().appusersId,
      orderBy: this.state.orderBy,
      last: this.state.perPageRecord,
      before: this.state.previousPageId,
      month: this.state.month,
      year: this.state.year,
    });

    if (this.state.currentPageNo !== 1) {
      this.setState({ currentPageNo: this.state.currentPageNo - 1 });
    }
  };

  handlePerPageRecord = (e) => {
    this.fetchProductList({
      deleted: 0,
      userId: authUserData().appusersId,
      first: e,
      month: this.state.month,
      year: this.state.year,
    });
    this.setState({ perPageRecord: e, currentPageNo: 1 });
  };

  searchCallbackDelayed = (e) => {
    e.persist();
    this.searchCallback(e);
  };

  searchProduct = (searchText) => {
    if (searchText !== "") {
      this.fetchProductList({
        search: searchText,
        deleted: 0,
        userId: this.state.authUserId,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
        month: this.state.month,
        year: this.state.year,
      });

      this.setState({ searchText, currentPageNo: 1 });
    } else {
      this.fetchProductList({
        deleted: 0,
        userId: this.state.authUserId,
        orderBy: this.state.orderBy,
        first: this.state.perPageRecord,
        month: this.state.month,
        year: this.state.year,
      });

      this.setState({ searchText: "" });
    }
  };

  GetSortingEnumValue(sortField, sortOrder) {
    let sortingEnumKey = "product_name";

    if (sortField === "productName" && sortOrder === "ascend") {
      sortingEnumKey = "product_name";
    }

    if (sortField === "productName" && sortOrder === "descend") {
      sortingEnumKey = "-product_name";
    }

    if (sortField === "price" && sortOrder === "ascend") {
      sortingEnumKey = "price";
    }

    if (sortField === "price" && sortOrder === "descend") {
      sortingEnumKey = "-price";
    }

    if (sortField === "yearlyAmountOfItemsProcured" && sortOrder === "ascend") {
      sortingEnumKey = "yearly_amount_of_items_procured";
    }

    if (
      sortField === "yearlyAmountOfItemsProcured" &&
      sortOrder === "descend"
    ) {
      sortingEnumKey = "-yearly_amount_of_items_procured";
    }

    if (sortField === "status" && sortOrder === "ascend") {
      sortingEnumKey = "status";
    }

    if (sortField === "status" && sortOrder === "descend") {
      sortingEnumKey = "-status";
    }
    return sortingEnumKey;
  }

  confirmDelete = async (productId, props) => {
    const { client } = props;
    await client
      .mutate({
        mutation: DELETE_PRODUCT_QUERY,
        variables: { productId: productId },
      })
      .then((result) => {
        this.fetchProductList({
          deleted: 0,
          userId: this.state.authUserId,
          orderBy: this.state.orderBy,
          first: this.state.perPageRecord,
          month: this.state.month,
          year: this.state.year,
        });
        this.setState({ currentPageNo: 1 });
        SuccessNotificationMsg("Success", "Product deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  showStatusPopconfirm = (index) => {
    this.setState({
      editIndexStatus: index,
      popConfirmShowStatus: true,
    });
  };

  handleCancelPopConfirmStatus = () => {
    this.setState({
      editIndexStatus: null,
      popConfirmShowStatus: false,
    });
  };

  handleChangeStatus = (record) => {
    let status = record.node.status === true ? false : true;

    const { client } = this.props;
    client
      .mutate({
        mutation: CHANGE_PRODUCT_STATUS_QUERY,
        variables: {
          productId: record.node.productId,
          status: status,
          userId: this.state.authUserId,
        },
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Product status change successfully");
        this.setState({
          editIndexStatus: null,
          popConfirmShowStatus: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({
          editIndexStatus: null,
          popConfirmShowStatus: false,
        });
      });
  };

  render() {
    const { data, loading } = this.state;
    const columns = [
      {
        title: "Product name",
        key: "productName",
        sorter: true,
        render: (node) => `${node.node.productName}`,
        width: "15%",
      },
      {
        title: "Short Desc",
        key: "shortDescription",
        render: (node) => `${node.node.shortDescription}`,
        width: "20%",
      },
      {
        title: "Price",
        key: "price",
        sorter: true,
        render: (node) => `${node.node.price}`,
        width: "10%",
      },
      {
        title: "Clicks (This Month)",
        key: "monthlyclick",
        sorter: false,
        render: (node) => `${node.node.monthlyclick.filterCount}`,
        width: "10%",
      },
      {
        title: "Total Clicks",
        key: "totalclick",
        sorter: false,
        render: (node) => `${node.node.totalclick.filterCount}`,
        width: "10%",
      },
      {
        title: "Items produced (Yearly)",
        key: "yearlyAmountOfItemsProcured",
        sorter: true,
        render: (node) => `${node.node.yearlyAmountOfItemsProcured}`,
        width: "15%",
      },
      {
        title: "Status",
        key: "status",
        sorter: true,
        render: (node) => (
          <>
            {node.node.status === true ? "Active" : "Inactive"}
            <Tooltip title="Please contact a TCL admin to request activation">
              <Badge count={0} dot>
                <QuestionCircleOutlined
                  style={{ fontSize: "18px", marginLeft: "5px" }}
                />
              </Badge>
            </Tooltip>
          </>
        ),
        width: "10%",
      },
      {
        width: "10%",
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        render: (text, node) => (
          <Space size="middle">
            <Link
              to={"/seller-edit-product/" + btoa(node.node.productId)}
              className="action"
            >
              <EditOutlined />
            </Link>
            <Popconfirm
              className="action"
              title="Are you sure delete this product ?"
              onConfirm={(id) =>
                this.confirmDelete(node.node.productId, this.props)
              }
              okText="Yes"
              placement="left"
            >
              <DeleteOutlined />
            </Popconfirm>
            <Link
              to={"/seller-product-document/" + btoa(node.node.productId)}
              className="action"
            >
              <UploadOutlined />
            </Link>
          </Space>
        ),
      },
    ];

    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Manage Product
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/seller-dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Manage Product</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <h3>My Product</h3>
                      </Col>
                      <Col flex="auto" align="end">
                        <Space>
                          <div className="searchhproduct">
                            <Search
                              placeholder="input search text"
                              onChange={this.searchCallbackDelayed}
                            />
                          </div>
                          <Link to="/seller-add-product">
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="small"
                              icon={<PlusCircleOutlined />}
                            >
                              ADD PRODUCT
                            </Button>
                          </Link>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                  <Table
                    className="table_grid"
                    columns={columns}
                    rowKey={(record) => record.node.id}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 970 }}
                  ></Table>

                  <div className="paginate">
                    <div>
                      <Select
                        defaultValue={this.state.perPageRecord + " / page"}
                        onChange={(e) => this.handlePerPageRecord(e)}
                        style={{ width: 120 }}
                      >
                        <Option value="5">5 / page</Option>
                        <Option value="10">10 / page</Option>
                        <Option value="20">20 / page</Option>
                        <Option value="50">50 / page</Option>
                      </Select>
                      Total Records : {this.state.totalRecords}
                    </div>
                    <div className="pages-count">
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== 1
                            ? this.previousPage
                            : ""
                        }
                      >
                        <LeftOutlined />
                      </Button>
                      &nbsp;&nbsp;{this.state.currentPageNo} /{" "}
                      {this.state.totalPage} &nbsp;&nbsp;
                      <Button
                        type="link"
                        htmlType="submit"
                        size="small"
                        onClick={
                          this.state.currentPageNo !== this.state.totalPage
                            ? this.nextPage
                            : ""
                        }
                      >
                        <RightOutlined />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ManageProduct);
