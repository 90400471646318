import React, { Component } from "react";
import { Col, Row, Checkbox, Button, Collapse } from "antd";
import { Link } from "react-router-dom";
import {
  PlusCircleOutlined,
  HeartOutlined,
  HeartFilled,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ProductLike from "./ProductLike";
import { ShowTextByCharLength } from "../../utils/Helpers";

import noimg from "../../images/noimg.png";
import productDefaultImg from "../../images/noimg-product.jpg";
import CO21 from "../../images/CO21.png";
import water1 from "../../images/water1.png";
import distance1 from "../../images/distance1.png";

const { Panel } = Collapse;
export class ProductCompare extends Component {
  state = {
    loading: true,
    data: [],
  };

  componentDidMount() {
    this.setState({
      data: this.props.location.state ? this.props.location.state.products : [],
    });
  }

  getProductCoverImage(files) {
    let coverImage = productDefaultImg;
    let picArray = [];

    if (files.edges.length > 0) {
      picArray = files.edges.filter(function (itm) {
        if (itm.node.fileType === "pic" && itm.node.deleted === 0) {
          return itm.node.awsFileUrl;
        } else {
          return null;
        }
      });
    }

    if (picArray.length > 0) {
      coverImage = picArray[0].node.awsFileUrl;
    }
    return coverImage;
  }

  addProductToCompare = () => {
    this.props.history.push({
      pathname: "/products",
      state: { compareProductArr: this.state.data },
    });
  };

  removeProductToCompare = (productId) => {
    this.setState({
      data: this.state.data.filter((s, sidx) => productId !== s.productId),
    });
  };

  goToProduct(productData) {
    this.props.history.push({
      pathname: "/product-detail/" + productData.slug,
      state: { productDetail: productData },
    });
  }

  render() {
    return (
      <div className="container">
        <div className="compare-product">
          <Row gutter={{ xs: 8, sm: 8 }}>
            <Col xs={4} className="compare_panel">
              <div className="inside">
                <div className="topdiv">
                  <h3>Compare</h3>
                  <span>(You can add upto 4 products to compare)</span>
                  {/* <ul>
                    <li>Organic Cotton Long-sleeve tee</li>
                    <li>TENCEL™ Lite Tank Top</li>
                  </ul> */}
                </div>
              </div>
            </Col>

            {this.state.data.length > 0
              ? this.state.data.map((product, index) => {
                  return (
                    <Col xs={5} key={index}>
                      <div className="prowrap">
                        <div className="comp_wrp">
                          <div className="imagdiv" onClick={() => this.goToProduct(product)}>
                            <img
                              alt={"prod-img-" + index}
                              src={this.getProductCoverImage(
                                product.productfilesmodelSet
                              )}
                            />

                            <Link
                              to="#"
                              onClick={() =>
                                this.removeProductToCompare(product.productId)
                              }
                              className="closecompare"
                            >
                              <CloseCircleOutlined />
                            </Link>
                          </div>
                          <div className="comp_desp">
                            <div className="Description">
                              <h3>
                                <span onClick={() => this.goToProduct(product)}>
                                  {product.productName}
                                </span>
                                <Checkbox className="favrout">
                                  <HeartOutlined className="nonfill" />
                                  <HeartFilled className="fill" />
                                </Checkbox>
                                <ProductLike productId={product.productId} />
                              </h3>
                            </div>
                            <div
                              className="price"
                              onClick={() => this.goToProduct(product)}
                            >
                              € {product.price}
                            </div>
                            <ul className="iconlist">
                              <li>
                                <div className="icondiv">
                                  <img alt="icon" src={CO21}></img>
                                  <div className="icon_txt">kg CO2</div>
                                </div>
                              </li>
                              <li>
                                <div className="icondiv">
                                  <img alt="icon" src={water1}></img>
                                  <div className="icon_txt">Liter</div>
                                </div>
                              </li>
                              <li>
                                <div className="icondiv">
                                  <img alt="icon" src={distance1}></img>
                                  <div className="icon_txt">Km</div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="comp_size_wrp">
                          {product.impactsizemodelSet &&
                            product.impactsizemodelSet.edges.length > 0 &&
                            product.impactsizemodelSet.edges.map((item) => (
                              <ul className="iconvalue">
                                <li className="size-label">
                                  {item.node.attributeValue.value}
                                </li>
                                <li>{item.node.kgco2}</li>
                                <li>{item.node.liter}</li>
                                <li>{item.node.kmtravel}</li>
                              </ul>
                            ))}
                        </div>
                      </div>
                    </Col>
                  );
                })
              : ""}

            {this.state.data.length < 4 ? (
              <Col xs={5}>
                <div className="prowrap">
                  <div className="noimage">
                    <img alt="no-img" src={noimg}></img>
                    <Button
                      type="link"
                      onClick={() => this.addProductToCompare()}
                    >
                      <PlusCircleOutlined />
                    </Button>
                  </div>
                </div>
              </Col>
            ) : (
              ""
            )}
            <Col xs={5}></Col>
          </Row>

          <Collapse collapsible="header" defaultActiveKey={["1"]}>
            <Panel header="Impact Details" key="1">
              <Row gutter={{ xs: 8, sm: 8 }}>
                <Col xs={4} className="compare_panel">
                  <div className="inside">
                    <div className="bottomdiv">
                      <ul>
                        <li className="green">Short Description</li>
                        <li className="green">Bio Based Material</li>
                        <li className="green">Refurbished Material</li>
                        <li className="green">Reused Material</li>
                        <li className="green">Recycled Material</li>
                        <li className="green">Vegan</li>
                        <li className="green">Second Life Possible</li>
                        <li className="green">Organic Fiber</li>
                        <li className="green">Social Impact</li>
                      </ul>
                    </div>
                  </div>
                </Col>

                {this.state.data.length > 0
                  ? this.state.data.map((product, index) => {
                      return (
                        <Col xs={5} key={index}>
                          <div className="prowrap">
                            <ul className="tablelist">
                              <li className="descp">
                                {product.shortDescription}
                              </li>

                              <li
                                className={
                                  product.biobasedMaterial ? "green" : "orange"
                                }
                              >
                                {product.biobasedMaterial ? (
                                  <CheckOutlined />
                                ) : (
                                  <CloseOutlined />
                                )}
                              </li>
                              <li
                                className={
                                  product.refurbishedMaterial
                                    ? "green"
                                    : "orange"
                                }
                              >
                                {product.refurbishedMaterial ? (
                                  <CheckOutlined />
                                ) : (
                                  <CloseOutlined />
                                )}
                              </li>
                              <li
                                className={
                                  product.reusedMaterial ? "green" : "orange"
                                }
                              >
                                {product.reusedMaterial ? (
                                  <CheckOutlined />
                                ) : (
                                  <CloseOutlined />
                                )}
                              </li>
                              <li
                                className={
                                  product.recycledMaterial ? "green" : "orange"
                                }
                              >
                                {product.recycledMaterial ? (
                                  <CheckOutlined />
                                ) : (
                                  <CloseOutlined />
                                )}
                              </li>
                              <li
                                className={product.vegan ? "green" : "orange"}
                              >
                                {product.vegan ? (
                                  <CheckOutlined />
                                ) : (
                                  <CloseOutlined />
                                )}
                              </li>
                              <li
                                className={
                                  product.secondLifePossible
                                    ? "green"
                                    : "orange"
                                }
                              >
                                {product.secondLifePossible ? (
                                  <CheckOutlined />
                                ) : (
                                  <CloseOutlined />
                                )}
                              </li>
                              <li
                                className={
                                  product.organicFiber ? "green" : "orange"
                                }
                              >
                                {product.organicFiber ? (
                                  <CheckOutlined />
                                ) : (
                                  <CloseOutlined />
                                )}
                              </li>
                              <li>
                                {ShowTextByCharLength(
                                  product.socialImpact,
                                  200
                                )}
                              </li>
                            </ul>
                          </div>
                        </Col>
                      );
                    })
                  : ""}

                {this.state.data.length < 4 ? (
                  <Col xs={5}>
                    <div className="prowrap"></div>
                  </Col>
                ) : (
                  ""
                )}
                <Col xs={5}></Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default ProductCompare;
