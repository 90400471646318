import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Col, Row, Button } from "antd";
import ProductLike from "../ProductLike";

import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { PRODUCT_LIST_QUERY } from "../../brand/product/ProductQuery";
import productDefaultImg from "../../../images/noimg-product.jpg";
import LatestProductListSkeleton from "./LatestProductListSkeleton";
export class LatestProductList extends Component {
  state = {
    loading: false,
    orderBy: "-product_id",
    data: [],
    perPageRecord: 8,
  };

  componentDidMount() {
    this.fetchProductList({
      deleted: 0,
      status: true,
      showonLandingPage: true,
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
    });
  }

  fetchProductList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: PRODUCT_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data:
            this.state.data.length > 0
              ? this.state.data.concat(result.data.allProduct.edges)
              : result.data.allProduct.edges,
          nextPageId: result.data.allProduct.pageInfo.endCursor,
          totalRecords: result.data.allProduct.filterCount,
          totalPage: Math.ceil(
            result.data.allProduct.filterCount / this.state.perPageRecord
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  goToProduct(productData) {
    this.props.history.push({
      pathname: "/product-detail/" + productData.node.slug,
      state: { productDetail: productData.node },
    });
  }

  getProductCoverImage(files) {
    let coverImage = productDefaultImg;
    let picArray = [];

    if (files.edges.length > 0) {
      picArray = files.edges.filter(function (itm) {
        if (itm.node.fileType === "pic" && itm.node.deleted === 0) {
          return itm.node.awsFileUrl;
        } else {
          return null;
        }
      });
    }

    if (picArray.length > 0) {
      coverImage = picArray[0].node.awsFileUrl;
    }
    return coverImage;
  }

  render() {
    const { data, loading } = this.state;
    return (
      <React.Fragment>
        <div className="title">
          The latest products <br />
          added to our
          <br /> collection.
        </div>
        <Row gutter={{ xs: 8, sm: 16, md: 30 }}>
          {data.length > 0 ? (
            data.map((product, index) => {
              return (
                <Col xs={12} sm={12} md={8} lg={6} key={product.node.productId}>
                  <div className="collect_div">
                    <div
                      className="imgdiv"
                      onClick={() => this.goToProduct(product)}
                    >
                      <img
                        alt="icon"
                        src={this.getProductCoverImage(
                          product.node.productfilesmodelSet
                        )}
                      ></img>
                    </div>

                    <h3>
                      <span onClick={() => this.goToProduct(product)}>
                        {product.node.productName}
                      </span>
                      <ProductLike productId={product.node.productId} />
                    </h3>
                    <p>
                      {product.node.shortDescription}
                    </p>
                    <div className="btndiv">
                      <Button
                        type="primary"
                        htmlType="button"
                        className="blkbtnsml"
                        onClick={() => this.goToProduct(product)}
                      >
                        € {product.node.price}
                      </Button>
                    </div>
                  </div>
                </Col>
              );
            })
          ) : data.length === 0 && loading === false ? (
            <div
              className="not-found"
              style={{ marginBottom: "20px", padding: "30px" }}
            >
              Not found product
            </div>
          ) : (
            <LatestProductListSkeleton />
          )}
        </Row>
      </React.Fragment>
    );
  }
}

export default withApollo(LatestProductList);
