import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  InstagramOutlined,
  FacebookFilled,
  LinkedinFilled,
} from "@ant-design/icons";

import "../../../components/common/about.scss";
import jasper from "../../../images/jasper.png";
import vincenzo from "../../../images/vinsenzo.png";
import samantha from "../../../images/samantha.jpg";

export class AboutUs extends Component {
  render() {
    return (
      <>
        <div class="landing_wrapper">
          <div class="landing_container">
            <div class="landing_raw_1">
              <div class="colum_1">
                <div class="text_wrap">
                  <p>
                    We are True Cost Label. A digital platform that makes it
                    simple to buy ethical fashion by revealing the invisible
                    environmental impact of products.
                  </p>
                  <p>
                    Through our in-depth Life Cycle Analysis reports, we break
                    down the environmental and social impact of progressive
                    brands that dare to use radical transparency to tell the
                    story behind their products.
                  </p>
                  <p>
                    By using radical transparency, we fight greenwashing. We
                    enable both brands and consumers to make more informed
                    decisions that involve less pollution and fairer work
                    conditions across the industry.
                  </p>
                  <p>
                    It is our mission to awaken the world to the impact of our
                    decisions and empower consumers and fashion brands alike.
                  </p>
                  <h3>
                    Join our community.
                    <br />
                    Up your game.
                    <br />
                    The time is now.
                  </h3>
                </div>
              </div>
              <div class="colum_2">
                <h1>SIGN UP FOR OUR NEWSLETTER TO</h1>
                <ul>
                  <li>
                    <div class="num">1).</div>
                    <p>Stay up to date about when True Cost Label goes live</p>
                  </li>
                  <li>
                    <div class="num">2).</div>
                    <p>Get updates when new brands are added to the platform</p>
                  </li>
                  <li>
                    <div class="num">3).</div>
                    <p>
                      Get notified when new blog posts are up so you can educate
                      yourself
                    </p>
                  </li>
                </ul>
                <Link to="/contact-us">SIGN UP</Link>
              </div>
            </div>
            <div class="landing_raw_1">
              <div class="colum_1">
                <div class="letsbreak">
                  <div class="lettext">
                    <h3>Let’s break it down.</h3>
                    <p>
                      Discover your true transparency throughout the supply
                      chain. Book your free pre-analysis session with one of our
                      in-house Life Cycle Analysis Experts,and be ready to up
                      your game.
                      <br />
                      <a href="mailto:info@truecostlabel.com">
                        BOOK YOUR FREE PRE-ANALYSIS SESSION
                        <br />
                      </a>
                    </p>
                  </div>
                </div>
                <div class="owners">
                  <div class="colam vin">
                    <div class="imgd">
                      <img
                        src={vincenzo}
                        alt="Vincenzo Fornoni"
                        height="246px"
                      />
                    </div>
                    <div class="dtl">
                      <h3>Vincenzo Fornoni</h3>
                      <p>
                        Founder &amp; <br />
                        Head of Bizdev
                      </p>
                    </div>
                  </div>

                  <div class="colam jas">
                    <div class="imgd">
                      <img
                        src={jasper}
                        alt="Jasper Roosendaal"
                        height="246px"
                      />
                    </div>
                    <div class="dtl">
                      <h3>Jasper Roosendaal</h3>
                      <p>
                        {" "}
                        Founder &amp; <br />
                        Head of Data
                      </p>
                    </div>
                  </div>

                  <div class="colam jas">
                    <div class="imgd">
                      <img src={samantha} alt="Samantha Koch" height="246px" />
                    </div>
                    <div class="dtl">
                      <h3>Samantha Koch</h3>
                      <p> Brand Director</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colum_2"></div>
            </div>
            <div class="ftr-btm">
              <label>FOLLOW US</label>
              <a href="https://www.facebook.com/True-Cost-Label-105602684234884/?view_public_for=105602684234884">
                <FacebookFilled />
              </a>
              <a href="https://www.linkedin.com/company/true-cost-label/about/">
                <LinkedinFilled />
              </a>
              <a href="https://www.instagram.com/truecostlabel/">
                <InstagramOutlined />
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutUs;
