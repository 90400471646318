import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Checkbox, Form } from "antd";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { GET_ATTRIBUTES_WITH_VALUES } from "../../common/AttributeQuery";

export class FilterSizeCategoryList extends Component {
  state = {
    loading: false,
    productTypeArr: [],
    sizeArr: [],
  };

  componentDidMount() {
    this.getAttributes();
  }

  getAttributes = () => {
    this.props.client
      .query({
        query: GET_ATTRIBUTES_WITH_VALUES,
        variables: { deleted: 0 },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let sizeArr = "";
        let productTypeArr = "";

        if (response.data.allAttribute.edges.length > 0) {
          response.data.allAttribute.edges.map((attributes) => {
            if (attributes.node.attributeId === "1") {
              sizeArr = attributes.node.attributevaluemodelSet;
            }
            if (attributes.node.attributeId === "2") {
              productTypeArr = attributes.node.attributevaluemodelSet;
            }
            return null;
          });
          this.setState({ productTypeArr, sizeArr });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  render() {
    const { sizeArr, productTypeArr } = this.state;

    return (
      <React.Fragment>
        <div className="category_one">
          <h3>CATEGORIES</h3>
          <Form>
            {productTypeArr.edges && productTypeArr.edges.length > 0
              ? productTypeArr.edges.map((attributes, index) => {
                  return (
                    <Form.Item key={index}>
                      <Checkbox
                        onChange={this.props.handleCategoryFilter(
                          attributes.node.slug
                        )}
                      >
                        {attributes.node.value}
                      </Checkbox>
                    </Form.Item>
                  );
                })
              : ""}
          </Form>
        </div>
        <div className="category_one">
          <h3>SIZES</h3>
          <Form>
            {sizeArr.edges && sizeArr.edges.length > 0
              ? sizeArr.edges.map((attributes, index) => {
                  return (
                    <Form.Item key={index}>
                      <Checkbox
                        onChange={this.props.handleSizeFilter(
                          attributes.node.slug
                        )}
                      >
                        {attributes.node.value}
                      </Checkbox>
                    </Form.Item>
                  );
                })
              : ""}
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

export default withApollo(FilterSizeCategoryList);
