import gql from "graphql-tag";

export const PRODUCT_COUNT_QUERY = gql`
  query allProduct($userId: Int, $deleted: Int) {
    allProduct(userId: $userId, deleted: $deleted) {
      totalCount
      filterCount
    }
  }
`;

export const ADD_PRODUCT_QUERY = gql`
  mutation createProduct(
    $authUserId: Int
    $supplyChainId: Int
    $productName: String
    $productType: [String]
    $productAccessories: String
    $yearlyAmountOfItemsProcured: String
    $averageLifetimeOfYourProduct: String
    $shortDescription: String
    $endOfLifeOthers: String
    $endOfLifeBiodegradable: Boolean
    $endOfLifeRecyclable: Boolean
    $endOfLifeProductAsAService: Boolean
    $endOfLifeRepairServiceBrand: Boolean
    $gender: String
    $price: String
    $productLink: String
    $linkToBrand: String
    $biobasedMaterial: Boolean
    $refurbishedMaterial: Boolean
    $reusedMaterial: Boolean
    $recycledMaterial: Boolean
    $vegan: Boolean
    $secondLifePossible: Boolean
    $socialImpact: String
    $colors: String
    $organicFiber: Boolean
    $detailDescription: String
  ) {
    createProduct(
      input: {
        userId: $authUserId
        supplyChainId: $supplyChainId
        productName: $productName
        productType: $productType
        productAccessories: $productAccessories
        yearlyAmountOfItemsProcured: $yearlyAmountOfItemsProcured
        averageLifetimeOfYourProduct: $averageLifetimeOfYourProduct
        shortDescription: $shortDescription
        endOfLifeOthers: $endOfLifeOthers
        endOfLifeBiodegradable: $endOfLifeBiodegradable
        endOfLifeRecyclable: $endOfLifeRecyclable
        endOfLifeProductAsAService: $endOfLifeProductAsAService
        endOfLifeRepairServiceBrand: $endOfLifeRepairServiceBrand
        price: $price
        productLink: $productLink
        biobasedMaterial: $biobasedMaterial
        refurbishedMaterial: $refurbishedMaterial
        reusedMaterial: $reusedMaterial
        recycledMaterial: $recycledMaterial
        vegan: $vegan
        secondLifePossible: $secondLifePossible
        socialImpact: $socialImpact
        colors: $colors
        organicFiber: $organicFiber
        gender: $gender
        linkToBrand: $linkToBrand
        detailDescription: $detailDescription
      }
    ) {
      product {
        productId
        productName
      }
    }
  }
`;

export const UPDATE_PRODUCT_QUERY = gql`
  mutation createProduct(
    $productRecordId: Int!
    $authUserId: Int!
    $supplyChainId: Int
    $productName: String
    $productType: [String]
    $productAccessories: String
    $yearlyAmountOfItemsProcured: String
    $averageLifetimeOfYourProduct: String
    $shortDescription: String
    $endOfLifeOthers: String
    $endOfLifeBiodegradable: Boolean
    $endOfLifeRecyclable: Boolean
    $endOfLifeProductAsAService: Boolean
    $endOfLifeRepairServiceBrand: Boolean
    $gender: String
    $price: String
    $productLink: String
    $linkToBrand: String
    $biobasedMaterial: Boolean
    $refurbishedMaterial: Boolean
    $reusedMaterial: Boolean
    $recycledMaterial: Boolean
    $vegan: Boolean
    $secondLifePossible: Boolean
    $socialImpact: String
    $colors: String
    $organicFiber: Boolean
    $detailDescription: String
  ) {
    updateProduct(
      input: {
        productId: $productRecordId
        userId: $authUserId
        supplyChainId: $supplyChainId
        productName: $productName
        productType: $productType
        productAccessories: $productAccessories
        yearlyAmountOfItemsProcured: $yearlyAmountOfItemsProcured
        averageLifetimeOfYourProduct: $averageLifetimeOfYourProduct
        shortDescription: $shortDescription
        endOfLifeOthers: $endOfLifeOthers
        endOfLifeBiodegradable: $endOfLifeBiodegradable
        endOfLifeRecyclable: $endOfLifeRecyclable
        endOfLifeProductAsAService: $endOfLifeProductAsAService
        endOfLifeRepairServiceBrand: $endOfLifeRepairServiceBrand
        price: $price
        productLink: $productLink
        biobasedMaterial: $biobasedMaterial
        refurbishedMaterial: $refurbishedMaterial
        reusedMaterial: $reusedMaterial
        recycledMaterial: $recycledMaterial
        vegan: $vegan
        secondLifePossible: $secondLifePossible
        socialImpact: $socialImpact
        colors: $colors
        organicFiber: $organicFiber
        gender: $gender
        linkToBrand: $linkToBrand
        detailDescription: $detailDescription
      }
    ) {
      product {
        productId
        productName
      }
    }
  }
`;

export const PRODUCT_LIST_QUERY = gql`
  query allProduct(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $deleted: Int
    $productId: Int
    $status: Boolean
    $showonLandingPage: Boolean
    $userId: Int
    $search: String
    $orderBy: [String]
    $endOfLifeProductAsAService: Boolean
    $vegan: Boolean
    $organicFiber: Boolean
    $recycledMaterial: Boolean
    $refurbishedMaterial: Boolean
    $biobasedMaterial: Boolean
    $companylist: [String]
    $genderlist: [String]
    $categorylist: [String]
    $sizelist: [String]
    $slugfield: String
    $productlist: [Int]
  ) {
    allProduct(
      productPk: $productId
      userId: $userId
      status: $status
      showonLandingPage: $showonLandingPage
      first: $first
      last: $last
      after: $after
      before: $before
      deleted: $deleted
      search: $search
      orderBy: $orderBy
      endOfLifeProductAsAService: $endOfLifeProductAsAService
      vegan: $vegan
      organicFiber: $organicFiber
      recycledMaterial: $recycledMaterial
      refurbishedMaterial: $refurbishedMaterial
      biobasedMaterial: $biobasedMaterial
      companylist: $companylist
      genderlist: $genderlist
      categorylist: $categorylist
      slugfield: $slugfield
      productlist: $productlist
      sizelist: $sizelist
    ) {
      totalCount
      filterCount
      edges {
        node {
          id
          productId
          productName
          slug
          shortDescription
          detailDescription
          status
          gender
          averageLifetimeOfYourProduct
          yearlyAmountOfItemsProcured
          price
          productLink
          linkToBrand
          biobasedMaterial
          refurbishedMaterial
          reusedMaterial
          recycledMaterial
          vegan
          colors
          secondLifePossible
          socialImpact
          organicFiber
          showonLandingPage
          clickCount
          productfilesmodelSet {
            edges {
              node {
                awsFileUrl
                fileType
                deleted
              }
            }
          }
          productattributemodelSet {
            edges {
              node {
                productAttributeId
                attributeValue {
                  attributeValueId
                  value
                  attribute {
                    attributeId
                    attributeName
                  }
                }
              }
            }
          }
          impactsizemodelSet(deleted: 0) {
            edges {
              node {
                impactsizeId
                weight
                kgco2
                liter
                kmtravel
                attributeValue {
                  value
                }
              }
            }
          }
          userId {
            appusersId
            onboardingmodelSet {
              edges {
                node {
                  company
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_PRODUCT_DETAIL_QUERY = gql`
  query allProduct($productId: Int, $slugfield: String) {
    allProduct(productPk: $productId, slugfield: $slugfield) {
      totalCount
      edges {
        node {
          id
          productId
          productName
          slug
          productAccessories
          yearlyAmountOfItemsProcured
          averageLifetimeOfYourProduct
          endOfLifeRecyclable
          endOfLifeBiodegradable
          endOfLifeProductAsAService
          endOfLifeRepairServiceBrand
          endOfLifeOthers
          shortDescription
          detailDescription
          status
          supplyChainId
          gender
          price
          productLink
          linkToBrand
          biobasedMaterial
          refurbishedMaterial
          reusedMaterial
          recycledMaterial
          vegan
          secondLifePossible
          socialImpact
          colors
          organicFiber
          showonLandingPage
          userId {
            appusersId
          }
          productfilesmodelSet {
            edges {
              node {
                awsFileUrl
                fileType
                deleted
              }
            }
          }
          productattributemodelSet {
            edges {
              node {
                productAttributeId
                attributeValue {
                  attributeValueId
                  value
                  attribute {
                    attributeId
                    attributeName
                  }
                }
              }
            }
          }
          impactsizemodelSet(deleted: 0) {
            edges {
              node {
                impactsizeId
                weight
                kgco2
                liter
                kmtravel
                attributeValue {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PRODUCT_QUERY = gql`
  mutation deleteProduct($productId: Int!) {
    deleteProduct(input: { productId: $productId }) {
      productId
    }
  }
`;

export const CHANGE_PRODUCT_STATUS_QUERY = gql`
  mutation createProduct($productId: Int!, $userId: Int!, $status: Boolean) {
    updateProduct(
      input: { productId: $productId, userId: $userId, status: $status }
    ) {
      product {
        productId
        productName
      }
    }
  }
`;

export const CHANGE_PRODUCT_SHOW_ON_LANDING_PAGE_QUERY = gql`
  mutation createProduct(
    $productId: Int!
    $userId: Int!
    $showonLandingPage: Boolean
  ) {
    updateProduct(
      input: {
        productId: $productId
        userId: $userId
        showonLandingPage: $showonLandingPage
      }
    ) {
      product {
        productId
        productName
      }
    }
  }
`;

export const INCREAMENT_PRODUCT_COUNT_QUERY = gql`
  mutation updateProductClick($productId: Int!) {
    updateProductClick(input: { productId: $productId }) {
      product {
        productId
        clickCount
      }
    }
  }
`;

export const GET_MOST_PRODUCT_CLICK_COUNT_QUERY = gql`
  query allProduct(
    $deleted: Int
    $status: Boolean
    $orderBy: [String]
    $first: Int
    $userId: Int
  ) {
    allProduct(
      deleted: $deleted
      status: $status
      orderBy: $orderBy
      first: $first
      userId: $userId
    ) {
      edges {
        node {
          productId
          productName
          clickCount
          userId {
            appusersId
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_COUNT_SUMMARY_QUERY = gql`
  query productCnt($brandId: Int) {
    productCnt(brandId: $brandId) {
      activePrdCnt
      totalPrdClick
      overPrdClick
    }
  }
`;

export const BRAND_PRODUCT_LIST_QUERY = gql`
  query allProduct(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $deleted: Int
    $productId: Int
    $status: Boolean
    $showonLandingPage: Boolean
    $userId: Int
    $search: String
    $orderBy: [String]
    $vegan: Boolean
    $organicFiber: Boolean
    $recycledMaterial: Boolean
    $refurbishedMaterial: Boolean
    $biobasedMaterial: Boolean
    $companylist: [String]
    $genderlist: [String]
    $categorylist: [String]
    $sizelist: [String]
    $slugfield: String
    $productlist: [Int]
    $month: Int
    $year: Int
  ) {
    allProduct(
      productPk: $productId
      userId: $userId
      status: $status
      showonLandingPage: $showonLandingPage
      first: $first
      last: $last
      after: $after
      before: $before
      deleted: $deleted
      search: $search
      orderBy: $orderBy
      vegan: $vegan
      organicFiber: $organicFiber
      recycledMaterial: $recycledMaterial
      refurbishedMaterial: $refurbishedMaterial
      biobasedMaterial: $biobasedMaterial
      companylist: $companylist
      genderlist: $genderlist
      categorylist: $categorylist
      slugfield: $slugfield
      productlist: $productlist
      sizelist: $sizelist
    ) {
      totalCount
      filterCount
      edges {
        node {
          id
          productId
          productName
          slug
          shortDescription
          detailDescription
          status
          gender
          averageLifetimeOfYourProduct
          yearlyAmountOfItemsProcured
          price
          productLink
          linkToBrand
          biobasedMaterial
          refurbishedMaterial
          reusedMaterial
          recycledMaterial
          vegan
          colors
          secondLifePossible
          socialImpact
          organicFiber
          showonLandingPage
          clickCount
          totalclick: productmonthclickcountSet {
            filterCount
          }
          monthlyclick: productmonthclickcountSet(month: $month, year: $year) {
            filterCount
          }
          productfilesmodelSet {
            edges {
              node {
                awsFileUrl
                fileType
                deleted
              }
            }
          }
          productattributemodelSet {
            edges {
              node {
                productAttributeId
                attributeValue {
                  attributeValueId
                  value
                  attribute {
                    attributeId
                    attributeName
                  }
                }
              }
            }
          }
          impactsizemodelSet(deleted: 0) {
            edges {
              node {
                impactsizeId
                weight
                kgco2
                liter
                kmtravel
                attributeValue {
                  value
                }
              }
            }
          }
          userId {
            appusersId
            onboardingmodelSet {
              edges {
                node {
                  company
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
