import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Input,
  Spin,
  Button,
  Form,
  Divider,
  Space,
  Layout,
  Select,
  AutoComplete,
} from "antd";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import AdminSidebar from "../common/AdminSidebar";

import {
  CREATE_API_CONFIG_QUERY,
  UPDATE_API_CONFIG_QUERY,
  GET_API_CONFIG_DETAIL_QUERY,
} from "./APIConfigQuery";

import { GET_COMPANY_DETAIL_QUERY } from "./../../brand/common/ProfileQuery";
const { Option } = Select;
const { Content } = Layout;

class EditAPI extends Component {
  state = {
    btnLoading: false,
    apiconfigRecordId: 0,
    apiLoading: false,
    isSaveButtonDisable: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    let apiconfigRecordId = this.props.match.params.apiconfigId
      ? atob(this.props.match.params.apiconfigId)
      : 0;

    if (apiconfigRecordId > 0) {
      this.getAPIConfigDetail(apiconfigRecordId);
      this.setState({ apiconfigRecordId });
    }
  }

  onFinish = () => {
    if (this.state.apiconfigRecordId === 0) {
      this.saveAPIConfig();
    } else {
      this.updateAPIConfig();
    }
  };

  saveAPIConfig = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: CREATE_API_CONFIG_QUERY,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg(
          "Success!",
          "API Configuration saved successfully."
        );
        this.formRef.current.resetFields();
        this.setState({ btnLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getAPIConfigDetail = (apiconfigRecordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .query({
        query: GET_API_CONFIG_DETAIL_QUERY,
        variables: { apiconfigId: apiconfigRecordId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState(response.data.allApiConfig.edges[0].node);
        this.formRef.current.setFieldsValue(
          response.data.allApiConfig.edges[0].node
        );
        this.setState({
          apiLoading: false,
          userId: response.data.allApiConfig.edges[0].node.userId.appusersId,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
        return false;
      });
  };

  updateAPIConfig = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });
    client
      .mutate({
        mutation: UPDATE_API_CONFIG_QUERY,
        variables: this.state,
      })
      .then((response) => {
        SuccessNotificationMsg(
          "Success!",
          "API Configuration updated successfully."
        );
        this.setState({ btnLoading: false });
        this.props.history.push("/admin-manage-api");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleSelectChange = (value) => {
    this.setState({ platform: value });
  };

  onSelect = (value, option) => {
    this.setState({ userId: option.id });
    const { client } = this.props;

    client
      .query({
        query: GET_API_CONFIG_DETAIL_QUERY,
        variables: { deleted: 0, userId: option.id },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response.data.allApiConfig.edges.length > 0) {
          ErrorNotificationMsg(
            "You can't add multiple API config details for selected brand."
          );
          this.setState({
            userId: option.id,
            isSaveButtonDisable: true,
          });
        } else {
          this.setState({ userId: option.id, isSaveButtonDisable: false });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  onSearch = (searchText) => {
    if (searchText.length < 3) {
      return true;
    }
    
    const { client } = this.props;
    client
      .query({
        query: GET_COMPANY_DETAIL_QUERY,
        variables: { deleted: 0 },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        let mcStatArr = [];

        if (response.data.allVendors.totalCount > 0) {
          response.data.allVendors.edges.map((objectValue, objectKey) => {
            let mcFieldObject = {};
            mcFieldObject["label"] = objectValue.node.company;
            mcFieldObject["value"] = objectValue.node.company;
            mcFieldObject["id"] = objectValue.node.userId.appusersId;
            mcStatArr.push(mcFieldObject);
            return null;
          });
        }
        this.setState({ searchOptions: mcStatArr });
      })
      .catch((e) => {
        //ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        return false;
      });
  };

  render() {
    return (
      <div className="contentpart">
        <Layout>
          <AdminSidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {this.state.apiconfigRecordId > 0
                  ? "Edit API Details"
                  : "Add API Details"}
              </div>
              <div className="content_wraper">
                <div className="stepWraper">
                  <div className="onboarding-form-body">
                    <Spin spinning={this.state.apiLoading}>
                      <Form
                        onFinish={this.onFinish}
                        ref={this.formRef}
                        autoComplete="off"
                      >
                        <Row gutter={[15]}>
                          {this.state.apiconfigRecordId > 0 ? (
                            ""
                          ) : (
                            <Col xs={24} sm={12} lg={8}>
                              <label>Select Brand*</label>
                              <Form.Item name="userId">
                                <AutoComplete
                                  onSelect={this.onSelect}
                                  onSearch={this.onSearch}
                                  options={this.state.searchOptions}
                                  filterOption={(inputValue, option) =>
                                    option.value
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  }
                                >
                                  <Input
                                    size="large"
                                    placeholder="Search & Select Brand"
                                  />
                                </AutoComplete>
                              </Form.Item>
                            </Col>
                          )}

                          <Col xs={24} sm={12} lg={8}>
                            <label>API Key*</label>
                            <Form.Item
                              name="apiKey"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input api key!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter API key"
                                onChange={this.handleInputChange("apiKey")}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={12} lg={8}>
                            <label>Secret Key*</label>
                            <Form.Item
                              name="secretKey"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input secret key!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter secret key"
                                onChange={this.handleInputChange("secretKey")}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={12} lg={8}>
                            <label>Password</label>
                            <Form.Item name="pwd">
                              <Input
                                placeholder="Enter password"
                                onChange={this.handleInputChange("pwd")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} lg={8}>
                            <label>API End Point*</label>
                            <Form.Item
                              name="apiendPoint"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input api end point!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter api end point"
                                onChange={this.handleInputChange("apiendPoint")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} lg={8}>
                            <label>Platform*</label>
                            <Form.Item
                              name="platform"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select platform!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select Platform"
                                onChange={this.handleSelectChange}
                              >
                                <Option value="shopify">Shopify</Option>
                                <Option value="magento">Magento</Option>
                                <Option value="wordpress">Wordpress</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Divider />
                        <Row gutter={[15]} className="bottomButtons">
                          <Col xs={24} align="end">
                            <Space>
                              <Button
                                type="secondary"
                                htmlType="button"
                                onClick={this.props.history.goBack}
                              >
                                BACK
                              </Button>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.btnLoading}
                                disabled={this.state.isSaveButtonDisable}
                              >
                                SUBMIT
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(EditAPI);
