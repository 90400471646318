import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import { Col, Row, Button, Carousel, Spin } from "antd";
import { ErrorNotificationMsg } from "../../../utils/NotificationHelper";
import { ShowTextByCharLength } from "../../../utils/Helpers";
import noimg from "../../../images/noimg-blog.jpg";

import { GET_IMPACT_INDICATOR_QUERY } from "../../admin/impactIndicator/ImpactIndicatorQuery";
export class ProductListBlog extends Component {
  state = {
    apiLoading: false,
    data: {},
  };

  createMarkup = (data) => {
    return { __html: data };
  };

  componentDidMount() {
    let impactIndicatorId = this.props.impactId ? this.props.impactId : 0;

    if (impactIndicatorId !== undefined && impactIndicatorId > 0) {
      this.getImpactIndicator({
        deleted: 0,
        orderBy: "impact_indicator_id",
        impactIndicatorId,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.impactId !== undefined &&
      prevProps.impactId !== this.props.impactId
    ) {
      let impactIndicatorId = this.props.impactId ? this.props.impactId : 0;
      if (impactIndicatorId !== undefined && impactIndicatorId > 0) {
        this.getImpactIndicator({
          deleted: 0,
          orderBy: "impact_indicator_id",
          impactIndicatorId,
        });
      }
    }
  }

  getImpactIndicator = (params) => {
    this.setState({ apiLoading: true });
    const { client } = this.props;

    client
      .query({
        query: GET_IMPACT_INDICATOR_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        if (result.data.allImpactIndicator.edges.length > 0) {
          this.setState({
            apiLoading: false,
            data: result.data.allImpactIndicator.edges[0].node,
          });
        } else {
          this.setState({
            apiLoading: false,
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  GetImages = (data) => {
    let files =
      data.impactindicatorfilesmodelSet !== undefined
        ? data.impactindicatorfilesmodelSet.edges
        : [];
    return (
      <React.Fragment>
        <Carousel autoplay>
          {files.length > 0 ? (
            files.map((itm, i) => (
              <div className="imgD" key={i}>
                <img alt="icon" src={itm.node.awsFileUrl} />
              </div>
            ))
          ) : (
            <div className="imgD" key={1}>
              <img alt="icon" src={noimg} />
            </div>
          )}
        </Carousel>
      </React.Fragment>
    );
  };

  render() {
    const { apiLoading, data } = this.state;
    return data.impactIndicatorId > 0 ? (
      <div className="blogbanner">
        <Spin spinning={apiLoading}>
          <div className="heading">
            <h5>YOU LIKE CLARITY ON</h5>
            <h3>{data.blogTitle}</h3>
          </div>
          <Row className="morecontent">
            <Col className="left_cont">
              <h4>{data.blogSubject}</h4>
              <div className="inr_cont">
                {this.GetImages(data)}
                <div
                  className="imgcont"
                  dangerouslySetInnerHTML={this.createMarkup(
                    ShowTextByCharLength(data.blogDescription, 380)
                  )}
                ></div>
              </div>
            </Col>
            <Col className="right_cont">
              <Link to={{ pathname: data.url }} target="_blank">
                <Button type="link" className="biglink">
                  Read more on our blog >
                </Button>
              </Link>
            </Col>
          </Row>
        </Spin>
      </div>
    ) : (
      ""
    );
  }
}

export default withApollo(ProductListBlog);
