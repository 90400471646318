import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Input,
  Spin,
  Button,
  Form,
  Divider,
  Space,
  Layout,
  Select,
} from "antd";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../../utils/NotificationHelper";
import { authUserData } from "../../../utils/Helpers";
import Sidebar from "../common/Sidebar";

import {
  CREATE_API_CONFIG_QUERY,
  UPDATE_API_CONFIG_QUERY,
  GET_API_CONFIG_DETAIL_QUERY,
} from "./APIConfigQuery";

const { Option } = Select;
const { Content } = Layout;

class EditAPI extends Component {
  state = {
    btnLoading: false,
    apiconfigRecordId: 0,
    apiLoading: false,
    isSaveButtonDisable: false,
    authUserId: authUserData().appusersId,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.getAPIConfigDetail();
  }

  onFinish = () => {
    if (this.state.apiconfigId && this.state.apiconfigId > 0) {
      this.updateAPIConfig();
    } else {
      this.saveAPIConfig();
    }
  };

  saveAPIConfig = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: CREATE_API_CONFIG_QUERY,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg(
          "Success!",
          "API Configuration saved successfully."
        );
        this.formRef.current.resetFields();
        this.setState({ btnLoading: false });
        this.props.history.push("/seller-dashboard");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getAPIConfigDetail = () => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .query({
        query: GET_API_CONFIG_DETAIL_QUERY,
        variables: { deleted: 0, userId: this.state.authUserId },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (
          response.data.allApiConfig &&
          response.data.allApiConfig.edges.length > 0
        ) {
          this.setState(response.data.allApiConfig.edges[0].node);
          this.formRef.current.setFieldsValue(
            response.data.allApiConfig.edges[0].node
          );
          this.setState({
            apiLoading: false,
            userId: response.data.allApiConfig.edges[0].node.userId.appusersId,
          });
        } else {
          this.setState({
            apiLoading: false,
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
        return false;
      });
  };

  updateAPIConfig = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });
    client
      .mutate({
        mutation: UPDATE_API_CONFIG_QUERY,
        variables: this.state,
      })
      .then((response) => {
        SuccessNotificationMsg(
          "Success!",
          "API Configuration updated successfully."
        );
        this.setState({ btnLoading: false });
        this.props.history.push("/seller-dashboard");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleSelectChange = (value) => {
    this.setState({ platform: value });
  };

  render() {
    return (
      <div className="contentpart">
        <Layout>
          <Sidebar />
          <Content>
            <div className="right_content_col">
              <div className="pagename">API Registration</div>
              <div className="content_wraper">
                <div className="stepWraper">
                  <div className="onboarding-form-body">
                    <Spin spinning={this.state.apiLoading}>
                      <p>
                        Using the instructions given on the previous screen.
                        Please generate the needed keys and passphrases to
                        complete an API connection to the TCL platform.
                      </p>
                      <p>
                        Once completed please fill the details relevant to your
                        store and click submit.{" "}
                      </p>
                      <Form
                        onFinish={this.onFinish}
                        ref={this.formRef}
                        autoComplete="off"
                      >
                        <Row gutter={[15]}>
                          <Col xs={24} sm={12} lg={8}>
                            <label>API Key*</label>
                            <Form.Item
                              name="apiKey"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input api key!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter API key"
                                onChange={this.handleInputChange("apiKey")}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={12} lg={8}>
                            <label>Secret Key*</label>
                            <Form.Item
                              name="secretKey"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input secret key!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter secret key"
                                onChange={this.handleInputChange("secretKey")}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={12} lg={8}>
                            <label>Password</label>
                            <Form.Item name="pwd">
                              <Input
                                placeholder="Enter password"
                                onChange={this.handleInputChange("pwd")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} lg={8}>
                            <label>API End Point*</label>
                            <Form.Item
                              name="apiendPoint"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input api end point!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter api end point"
                                onChange={this.handleInputChange("apiendPoint")}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} lg={8}>
                            <label>Platform*</label>
                            <Form.Item
                              name="platform"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select platform!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select Platform"
                                onChange={this.handleSelectChange}
                              >
                                <Option value="shopify">Shopify</Option>
                                <Option value="magento">Magento</Option>
                                <Option value="wordpress">Wordpress</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Divider />
                        <Row gutter={[15]} className="bottomButtons">
                          <Col xs={24} align="end">
                            <Space>
                              <Button
                                type="secondary"
                                htmlType="button"
                                onClick={this.props.history.goBack}
                              >
                                BACK
                              </Button>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.btnLoading}
                                disabled={this.state.isSaveButtonDisable}
                              >
                                SUBMIT
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(EditAPI);
