import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout } from "antd";
import { Auth } from "aws-amplify";

import {
  AppstoreOutlined,
  EditOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
class AdminSidebar extends Component {
  state = {
    authUser: "",
    currentRoute: "",
  };

  async componentDidMount() {
    const tokens = await Auth.currentSession();
    const userData = tokens.getIdToken().payload;
    this.setState({
      authUser: userData,
      currentRoute: this.props.location.pathname,
    });
  }

  render() {
    const { name } = this.state.authUser;
    const { currentRoute } = this.state;

    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width="250"
        onBreakpoint={(broken) => {
          //console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          //console.log(collapsed, type);
        }}
      >
        <div className="profilebar">{name}</div>
        <div className="sidebar_menu">
          <ul className="navigate">
            <li className={currentRoute === "/admin-dashboard" ? "active" : ""}>
              <Link to="/admin-dashboard">
                <i className="menu-icon">
                  <AppstoreOutlined />
                </i>
                Dashboard
              </Link>
            </li>
            <li
              className={currentRoute === "/admin-manage-brand" ? "active" : ""}
            >
              <Link to="/admin-manage-brand">
                {" "}
                <i className="menu-icon">
                  <PlusSquareOutlined />
                </i>
                Manage Brand
              </Link>
            </li>
            {/* <li
              className={
                currentRoute === "/admin-manage-consumer" ? "active" : ""
              }
            >
              <Link to="/admin-manage-consumer">
                {" "}
                <i className="menu-icon">
                  <PlusSquareOutlined />
                </i>
                Manage Consumer
              </Link>
            </li> */}
            <li
              className={
                currentRoute === "/admin-manage-product" ? "active" : ""
              }
            >
              <Link to="/admin-manage-product">
                {" "}
                <i className="menu-icon">
                  <PlusSquareOutlined />
                </i>
                Manage Product
              </Link>
            </li>
            <li
              className={
                currentRoute === "/admin-manage-supplychain" ? "active" : ""
              }
            >
              <Link to="/admin-manage-supplychain">
                {" "}
                <i className="menu-icon">
                  <PlusSquareOutlined />
                </i>
                Manage Supplychain
              </Link>
            </li>
            <li
              className={
                currentRoute === "/admin-manage-request-quote" ? "active" : ""
              }
            >
              <Link to="/admin-manage-request-quote">
                {" "}
                <i className="menu-icon">
                  <EditOutlined />
                </i>
                Manage Request Quote
              </Link>
            </li>
            <li
              className={
                currentRoute === "/admin-manage-get-support" ? "active" : ""
              }
            >
              <Link to="/admin-manage-get-support">
                {" "}
                <i className="menu-icon">
                  <QuestionCircleOutlined />
                </i>
                Manage Get Support
              </Link>
            </li>
            <li
              className={currentRoute === "/admin-manage-api" ? "active" : ""}
            >
              <Link to="/admin-manage-api">
                {" "}
                <i className="menu-icon">
                  <EditOutlined />
                </i>
                Manage API Config
              </Link>
            </li>
            <li
              className={
                currentRoute === "/admin-manage-impact-indicator"
                  ? "active"
                  : ""
              }
            >
              <Link to="/admin-manage-impact-indicator">
                {" "}
                <i className="menu-icon">
                  <PlusSquareOutlined />
                </i>
                Manage Impact Indicator
              </Link>
            </li>
            <li
              className={currentRoute === "/admin-manage-faq" ? "active" : ""}
            >
              <Link to="/admin-manage-faq">
                {" "}
                <i className="menu-icon">
                  <PlusSquareOutlined />
                </i>
                Manage FAQ
              </Link>
            </li>
          </ul>
        </div>
      </Sider>
    );
  }
}

export default withRouter(AdminSidebar);
